import { format } from 'date-fns'
import httpService from './httpService'
import {
  BookingFloorPlanCategories,
  BookingSearchProps,
  BookingLocations,
  BookingFloorPlans,
  BookingSearchPayload,
  BookingFeaturesPayload,
  BookingBookingPayload,
  BookingPostResponse,
  BookingsByEmployeePayload,
  BookingProblemDetailPostResponse,
  BookingCancellationPostBody,
  BookingCheckInOutPayload,
  BookingCheckInOutPost,
  BookingZone,
  BookingFeature,
  ForwardDeskBookingsResponse,
  MostUsedDeskResponse, 
  BlockBookingPostResponse,
  BookingComponent, 
  BookingFloorPlanResponse,
} from './types'
import { BookingSearchRecentParams } from '../../components/Booking/types'
import {FloorPlanRequest} from "../../types/floorPlanRequest";
import {EditorRequest} from "../../types/editorRequest";
import {EditorResponse} from "../../types/editorResponse";

const apiPaths = {
  query: () => `/desk-booking/api/Book/ByQuery`,
  queryRange: () => `/desk-booking/api/Book/ByQueryRange`,
  queryRecent: () => `/desk-booking/api/Book/Recent`,
  locations: () => `/booking/api/location/all`,
  siteById: (siteId: string) => `/booking/api/collection/site/${siteId}`,
  floorPlans: () => `/booking/api/floorPlan/all`,
  floorPlanById: (floorPlanId: string) => `/booking/api/collection/floorPlan/${floorPlanId}`,
  floorPlanCategories: () => `/booking/api/FloorPlanCategory/All`,
  floorPlanCategoryById: (floorPlanCategoryId: string) =>
    `/booking/api/FloorPlanCategory/${floorPlanCategoryId}`,
  features: () => `/booking/api/feature`,
  featuresByFloorPlan: () => `/booking/api/feature/all`,
  featureById: (featureId: number) => `/booking/api/collection/feature/${featureId}`,
  bookings: () => `/desk-booking/api/book`,
  bookOnBehalfOfEmployee: () => `/desk-booking/api/book/ByManager`,
  bookOnBehalfOfVisitor: () => `/desk-booking/api/book/ByManager`,
  bookingById: (bookingId: string) => `/desk-booking/api/book?id=${bookingId}`,
  bookingByEmployeeId: () => `/desk-booking/api/book/ByEmployee`,
  bookingBlock: () => `/desk-booking/api/book/block`,
  zone: (slug: string, floorplanId: number) => `/booking/api/Zone/${slug}?id=${floorplanId}`,
  zonesAll: () => `/booking/api/Zone/All`,
  cancelBookingById: () => `/desk-booking/api/Cancellation`,
  cancelBookingByIdByManager: () => `/desk-booking/api/Cancellation/manager`,
  checkInOut: () => `/desk-booking/api/CheckInOut`,
  cache: () => '/booking/api/Cache',
  getForwardDeskBookingsCount: (employeeId: number) =>
    `/desk-booking/api/Book/GetForwardDeskBookingsCount?EmployeeId=${employeeId}&FromDate=${format(
      new Date(),
      'yyyy-MM-dd'
    )}`,
  mostUsedDesk: () => '/desk-booking/api/book/GetMostUsedDesk',
  getAllComponents: () => '/booking/api/component/all',
  createFloorPlan: () => '/booking/api/FloorPlan',
  updateFloorPlan: () => '/booking/api/FloorPlan',
  editorCreate: () => '/booking/api/editor',
  editorDelete: () => '/booking/api/editor',
  getFloorPlan: () => '/booking/api/FloorPlan'
}

const bookingService = {
  clearCache: async (): Promise<void> => {
    const url = `${apiPaths.cache()}/DeleteAll`
    return httpService.del(url)
  },

  search: async (
    abortController: AbortController,
    queryParams: BookingSearchProps
  ): Promise<BookingSearchPayload> => {
    let url
    if (queryParams.dateTo) {
      url = `${apiPaths.queryRange()}?DateFrom=${queryParams.date}&DateTo=${
        queryParams.dateTo
      }&from=${queryParams.from}&to=${queryParams.to}&floorplanid=${queryParams.floorplanId}`
    } else {
      url = `${apiPaths.query()}?date=${queryParams.date}&from=${queryParams.from}&to=${
        queryParams.to
      }&floorplanid=${queryParams.floorplanId}`
    }

    const config = abortController ? { signal: abortController.signal } : undefined
    return httpService.get<BookingSearchPayload>(url, config)
  },

  queryRecent: async (queryParams: BookingSearchRecentParams): Promise<BookingSearchPayload> => {
    const url = `${apiPaths.queryRecent()}?FromDateTime=${queryParams.fromDateTime}&date=${
      queryParams.date
    }&from=${queryParams.from}&to=${queryParams.to}&floorplanid=${queryParams.floorPlanID}`
    return httpService.get<BookingSearchPayload>(url)
  },

  checkInOut: async (
    booking: Partial<BookingCheckInOutPost>
  ): Promise<BookingCheckInOutPayload> => {
    const url = apiPaths.checkInOut()
    return httpService.post(url, booking)
  },

  getFloorPlanCategory: async (): Promise<BookingFloorPlanCategories> => {
    const url = apiPaths.floorPlanCategories()
    return httpService.get(url)
  },

  getLocations: async (): Promise<BookingLocations> => {
    const url = apiPaths.locations()
    return httpService.get(url)
  },

  getZonesByFloorPlan: async (floorplanId: number): Promise<{ zones: BookingZone[] }> => {
    const url = apiPaths.zone('byFloorPlan', floorplanId)
    return httpService.get(url)
  },

  getZonesAll: async (): Promise<{ zones: BookingZone[] }> => {
    const url = apiPaths.zonesAll()
    return httpService.get(url)
  },

  createBooking: async (booking: Partial<BookingBookingPayload>): Promise<BookingPostResponse> => {
    const url = apiPaths.bookings()
    return httpService.post(url, booking)
  },

  createBookingOnBehalfOfEmployee: async (
    booking: Partial<BookingBookingPayload>
  ): Promise<BookingPostResponse> => {
    const url = apiPaths.bookOnBehalfOfEmployee()
    return httpService.post(url, booking)
  },

  createBookingOnBehalfOfVisitor: async (
    booking: Partial<BookingBookingPayload>
  ): Promise<BookingPostResponse> => {
    const url = apiPaths.bookOnBehalfOfVisitor()
    return httpService.post(url, booking)
  },

  createBlockBooking: async (
    booking: Partial<BookingBookingPayload>
  ): Promise<BlockBookingPostResponse> => {
    const url = apiPaths.bookingBlock()
    return httpService.post(url, booking)
  },

  createFeature: async (feature: Partial<BookingFeature>[]): Promise<void> => {
    const url = apiPaths.features()
    return httpService.post(url, { features: [...feature] })
  },

  updateFeature: async (feature: Partial<BookingFeature>[]): Promise<void> => {
    const url = apiPaths.features()
    return httpService.put(url, { features: [...feature] })
  },

  getFeaturesByPlan: async (
    abortController: AbortController,
    floorPlanId: number
  ): Promise<BookingFeaturesPayload> => {
    const url = `${apiPaths.featuresByFloorPlan()}?floorplanid=${floorPlanId}`

    const config = abortController ? { signal: abortController.signal } : undefined
    return httpService.get(url, config)
  },

  getBookingByEmployeeId: async (
    abortController: AbortController,
    employeeId: number,
    start: string,
    end: string
  ): Promise<BookingsByEmployeePayload> => {
    const url = `${apiPaths.bookingByEmployeeId()}?employeeId=${employeeId}&start=${start}&end=${end}`

    const config = abortController ? { signal: abortController.signal } : undefined
    return httpService.get(url, config)
  },

  getBookingById: async (
    abortController: AbortController,
    id: string
  ): Promise<{ booking: BookingBookingPayload }> => {
    const url = `${apiPaths.bookingById(id)}`

    const config = abortController ? { signal: abortController.signal } : undefined
    return httpService.get(url, config)
  },

  getFloorPlans: async (): Promise<BookingFloorPlans> => {
    const url = apiPaths.floorPlans()
    return httpService.get(url)
  },

  cancelBookingById: async (
    body: BookingCancellationPostBody
  ): Promise<BookingProblemDetailPostResponse> => {
    const url = apiPaths.cancelBookingById()
    return httpService.post(url, body)
  },

  cancelBookingByIdByManager: async (
    body: BookingCancellationPostBody
  ): Promise<BookingProblemDetailPostResponse> => {
    const url = apiPaths.cancelBookingByIdByManager()
    return httpService.post(url, body)
  },

  getForwardDeskBookings: async (employeeId: number): Promise<ForwardDeskBookingsResponse> => {
    const url = apiPaths.getForwardDeskBookingsCount(employeeId)
    return httpService.get(url)
  },

  getMostUsedDesk: async (employeeId: number): Promise<MostUsedDeskResponse> => {
    const url = `${apiPaths.mostUsedDesk()}?employeeId=${employeeId}`
    return httpService.get(url)
  },

  getAllComponents: async (): Promise<{ components: BookingComponent[] }> => {
    const url = apiPaths.getAllComponents()
    return httpService.get(url)
  },
  createFloorPlan: async (body: FloorPlanRequest) : Promise<number> =>
      httpService.post(apiPaths.createFloorPlan(), body),
  updateFloorPlan: async (body: FloorPlanRequest) : Promise<number> =>
      httpService.put(apiPaths.updateFloorPlan(), body),
  editorCreate: async (body: EditorRequest) : Promise<EditorResponse> =>
      httpService.post(apiPaths.editorCreate(), body),
  editorDelete: async (floorPlanId: number) : Promise<any> => {
    const path = `${apiPaths.editorDelete()}?id=${floorPlanId}`;
    return httpService.del(path)
  },
  getFloorPlan: async (
      floorPlanId: number
  ): Promise<BookingFloorPlanResponse> => {
    const url = `${apiPaths.getFloorPlan()}?id=${floorPlanId}`
    return httpService.get(url)
  },
}

export { bookingService }

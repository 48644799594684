import Chip from '@mui/material/Chip'
import WarningAmberIcon from '@mui/icons-material/WarningAmber'
import DoneIcon from '@mui/icons-material/Done'
import styled from 'styled-components'

export const statusChipThemes = {
  closed: {
    textColor: '#f5f5f5',
    bgColor: '#616161',
  },
  current: {
    textColor: '#F2FFF2',
    bgColor: '#1E4620',
  },
  next: {
    textColor: '#FFF9EA',
    bgColor: '#A27403',
  }
}

interface StatusChipProps {
  label: string
  type: keyof typeof statusChipThemes
}

export function StatusChip({ type, label }: StatusChipProps) {
  const theme = statusChipThemes[type]
  if (!theme) return null
  const StyledChip = styled(Chip)`
&.MuiChip-root {
  background-color: ${theme.bgColor}
  color: ${theme.textColor}
  padding: 4px 10px;
  height: auto;
  font-size: 14px;
  font-weight: lighter;
  letter-spacing: 0em;
  .MuiChip-icon {
    color: ${theme.textColor};
  }
  .MuiChip-label {
    white-space: normal
  }
`
  return <StyledChip label={label} data-testid="status-chip" />
}

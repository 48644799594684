import { Info } from "@mui/icons-material"
import { Box } from "@mui/material"
import { RequestType } from "../../models"
import { CustomWidthTooltip } from "../../shared/UI/CustomToolTip/CustomTooltip"
import { callOutFromContent } from "../../utils/constants"

export type ToolTipProps = {
  type: RequestType,
}

export const getPaidOrTotalString = ({ type }: ToolTipProps): string => type === RequestType.ON_CALL || type === RequestType.ON_CALL_M
    ? 'Paid Hours'
    : 'Total Hours'

export const renderHeadingWithTooltip = ({ type }: ToolTipProps): JSX.Element | null => {
  if (type === RequestType.ON_CALL || type === RequestType.ON_CALL_M) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: 0.6, flexGrow: 1 }}>
        <CustomWidthTooltip
          title={callOutFromContent.onCallHoursTooltip}
          data-testid="TotalOrPaid-hours-tooltip"
        >
          <Info fontSize="small" sx={{ color: '#20C5A0', marginLeft: '4px' }} />
        </CustomWidthTooltip>
      </Box>
    )
  }
  return null
}
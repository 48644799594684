import { Box } from '@mui/material'
import { gridRowCountSelector, useGridApiContext, useGridSelector } from '@mui/x-data-grid-pro'
import Paragraph from '../../shared/UI/Paragraph'

function CustomGridFooter({ selectedRows, testId }: { selectedRows: number; testId: string }) {
  const apiRef = useGridApiContext()
  const totalRowCount = useGridSelector(apiRef, gridRowCountSelector)
  return (
    <Box pt={3} style={{ cursor: 'default' }} borderTop="1px solid rgba(224, 224, 224, 1)" color="#707070" data-testid={testId}>
      Rows:
      <Paragraph
        weight="bold"
        style={{ display: 'inline', margin: '0 24px 0 4px' }}
        color="inherit"
        data-testid={`${testId}-row-count`}
      >
        {totalRowCount}
      </Paragraph>
      <Paragraph
        weight="bold"
        style={{ display: 'inline', margin: '0 4px 0 0' }}
        color="inherit"
        data-testid={`${testId}-selected-rows`}
      >
        {selectedRows}
      </Paragraph>
      Selected
    </Box>
  )
}

export default CustomGridFooter

import React from 'react'
import { Box } from '@mui/material'
import { format, isValid } from "date-fns"

interface SubmissionStatusProps {
  submissionLabel: string
  status: 'closed' | 'current' | 'next'
  statusDate?: Date | string
}

export function SubmissionStatus({ submissionLabel, status, statusDate }: SubmissionStatusProps) {
  let formattedTime = 'N/A'
  let formattedDate = 'N/A'

  const dateToFormat = typeof statusDate === 'string' ? new Date(statusDate) : statusDate

  if (dateToFormat && isValid(dateToFormat)) {
    formattedTime = format(dateToFormat, 'HH:mm')
    formattedDate = format(dateToFormat, 'do MMMM')
  }

  let displayText
  if (status === 'next') {
    displayText = 'Not Available'
  } else if (!dateToFormat || !isValid(dateToFormat)) {
    displayText = ' - '
  } else {
    displayText = `${formattedDate}, ${formattedTime}`
  }

  return (
    <span data-testid="submission-status">
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <span style={{ fontWeight: 'bold' }}>{submissionLabel}</span>
        <span style={{ marginLeft: '4px' }}>{displayText}</span>
      </Box>
    </span>
  )
}
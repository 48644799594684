import { TextField } from '@mui/material'
import { DateRange } from '../../../../models/enhancement/date-range'
import { RequestDatesCard } from '../../../../shared/layout/RequestDatesCard'

interface CallOutDurationProps {
  totalHours: string
  dateRange: DateRange
  dateSubmitted: string | undefined
  comment?: string
}

export function CallOutDuration({ totalHours, dateRange, dateSubmitted, comment = '' }: CallOutDurationProps) {
  const displayTotalHours = Number(totalHours).toFixed(2).toString()
  const issueDescription = <TextField
    label="Issue Description"
    fullWidth
    multiline
    rows={4}
    disabled
    value={comment}
    data-testid="description"
  />
  return (
    <RequestDatesCard
      title="Duration"
      totalHours={displayTotalHours}
      dateFrom={dateRange.start}
      dateTo={dateRange.end}
      dateSubmitted={dateSubmitted}
      showFullTimestamp
      extraItems={[issueDescription]}
    />
  )
}

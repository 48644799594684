import { Typography as MuiTypography } from '@mui/material'
import styled from 'styled-components'

type Props = {
  title?: string | number
  'data-testid'?: string
}

const StyledTypography = styled(MuiTypography)`
  &.MuiTypography-root {
    font-weight: 500;
    font-size: 18px;
    color: #000000;
    letter-spacing: 0.2px;
  }
`

function HeadingTwo({ title, 'data-testid': testId }: Props) {
  return <StyledTypography data-testid={testId}>{title}</StyledTypography>
}

export default HeadingTwo

import { Grid, Stack, TextField } from '@mui/material'
import { format } from 'date-fns'
import { useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import {
  SetShowModalPayload,
  hideModal,
  showModalDialog,
} from '../../redux/reducers/appSettingsReducer'
import { showErrorMessage, showSuccessMessage } from '../../redux/reducers/snackbarReducer'
import { RootStore, useAppDispatch } from '../../redux/store'
import { requestsService } from '../../services/requestsService'
import { manualRequestsService } from '../../services/myActionsService'
import Alert from '../../shared/UI/Alert/Alert'
import Button from '../../shared/UI/Button'
import CardTitle from '../../shared/UI/CardTitle'
import InstanceStatusBanner from '../../shared/UI/InstanceStatusBanner'
import LoadingIndicator from '../../shared/UI/LoadingIndicator'
import Modal from '../../shared/UI/Modal'
import MyActionsPageHeader from '../../shared/UI/MyActionsPageHeader'
import MySummaryEntitlementSplit from '../../shared/UI/MySummaryEntitlementSplit/MySummaryEntitlementSplit'
import NoDataFound from '../../shared/UI/NoDataFound'
import Paragraph from '../../shared/UI/Paragraph'
import Card from '../../shared/layout/Card'
import { BaseResponse } from '../../types/base-response'
import { EntitlementYearDatesResponse } from '../../types/entitlement'
import { EntitlementSplit } from '../../types/entitlement-split'
import { RequestDetailResponse, RequestUpdateRequest } from '../../types/requests'
import { toInteger } from '../../utils/conversion'
import UserErrorMessage from '../../utils/errorFilter'
import { OccurrenceRequestProps, RequestAction, RequestStatus } from '../OccurrenceRequest/types'

function AdjustmentOccurrence({ occurrenceType }: OccurrenceRequestProps) {
  const { id: requestId } = useParams<{ id: string }>()
  const dispatch = useAppDispatch()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [requestDetail, setRequestDetail] = useState<RequestDetailResponse | null>(null)
  const [initialRequestDetail, setInitialRequestDetail] = useState<RequestDetailResponse | null>(
    null
  )
  const [hasChanged, setHasChanged] = useState<boolean>(false)
  const [hasBothDates, setHasBothDates] = useState<boolean>(true)
  const [financialYearDates, setFinancialYearDates] = useState<EntitlementYearDatesResponse>()

  const [entitlementSplit, setEntitlementSplit] = useState<EntitlementSplit>()
  const [previousYear, setPreviousYear] = useState<boolean>(false)

  const minDate = new Date('0001/01/01')

  const checkForDates = (requestDateTo: string) => {
    setHasBothDates(new Date(requestDateTo) > minDate)
  }

  const { showModal, title, message, type, buttonLabel } = useSelector<
    RootStore,
    SetShowModalPayload
  >((state: RootStore) => state.appSettings.modalProps)

  const activeRoute = useSelector<RootStore, string>(
    (state: RootStore) => state.appSettings.activeRoute
  )

  const { allEmployees } = useSelector((state: RootStore) => state.appSettings)

  const { currentEntitlementPeriodResponse } = useSelector((state: RootStore) => state.appSettings)
  const navigate = useNavigate()

  useEffect(() => {
    setIsLoading(true)
    manualRequestsService
      .getHolidayRequestDetail(requestId as string)
      .then(data => {
        setIsLoading(false)
        setRequestDetail(data)
        setInitialRequestDetail(data)
        setFinancialYearDates(
          allEmployees.find(f => f.employeeId === data.employeeId)?.entitlementYearDates
        )
        checkForDates(data.dateTo)
        setEntitlementSplit({
          general: {
            hours: data?.summary.totalHours,
            summerHours: data?.summary.totalSummerHours,
            winterHours: data?.summary.totalWinterHours,
          },
          remaining: {
            hours: data?.summary.remainingHours,
            summerHours: data?.summary.remainingSummerHours,
            winterHours: data?.summary.remainingWinterHours,
          },
          requested: {
            hours: data?.summary.requestedHours,
            summerHours: data?.summary.requestedSummerHours,
            winterHours: data?.summary.requestedWinterHours,
          },
          changeRequests: {
            hours: data?.summary.changeRequestHours,
            summerHours: data?.summary.changeRequestHoursSummer,
            winterHours: data?.summary.changeRequestHoursWinter,
          },
          ifApproved: {
            hours: data?.summary.ifApprovedHours,
            summerHours: data?.summary.ifApprovedHoursSummer,
            winterHours: data?.summary.ifApprovedHoursWinter,
          },
        })
        setPreviousYear(
          currentEntitlementPeriodResponse?.entitlementPeriod.toLowerCase() === 'financialyear'
            ? new Date(data.dateFrom).getFullYear() < currentEntitlementPeriodResponse.year
            : new Date(data.dateFrom).getFullYear() < new Date().getFullYear()
        )
      })
      .catch(err => {
        setIsLoading(false)
        setRequestDetail(null!)
        navigate(activeRoute)
        const response: BaseResponse = err.response.data
        response.errors.forEach(error => {
          dispatch(showErrorMessage(<UserErrorMessage name={error.name} />))
        })
      })
  }, [])

  const displayClashModal = () => {
    dispatch(
      showModalDialog({
        title: 'Are you sure?',
        message: `Are you sure you would like to decline this request?`,
        buttonLabel: 'Decline',
        type: 'question',
        showModal: true,
      })
    )
  }

  const requestDetailHasChanged = useCallback((): boolean => {
    if (requestDetail && initialRequestDetail) {
      return Object.keys(requestDetail).some(
        key =>
          requestDetail[key as keyof RequestDetailResponse] !==
          initialRequestDetail[key as keyof RequestDetailResponse]
      )
    }
    return false
  }, [initialRequestDetail, requestDetail])

  useEffect(() => {
    setHasChanged(requestDetailHasChanged())
  }, [requestDetailHasChanged])

  const transformBody = (): RequestUpdateRequest | undefined => {
    if (!requestDetail) {
      return
    }
    return {
      requestId: requestDetail.requestId,
      requestStatusId: requestDetail.requestStatusId,
      requestTypeId: requestDetail.requestTypeId,
      manualTypeId: requestDetail.manualTypeId,
      employeeId: requestDetail.employeeId,
      applyChangesToRota: false,
      departmentId: requestDetail.departmentId,
      reviewerComments: requestDetail.reviewerComments,
      days: requestDetail.requestDays
        .filter(day => day.requestDayId > 0)
        .map(day => ({
          requestDayId: day.requestDayId,
          requestDayDate: day.day,
          hours: Number(day.hours) < 0 ? 0 : Number(day.hours),
          checked: day.checked,
        })),
      dateFrom: format(new Date(requestDetail.dateFrom.toString()), 'yyyy-MM-dd'),
      dateTo: format(new Date(requestDetail.dateTo.toString()), 'yyyy-MM-dd'),
      requestActionId: RequestStatus.DECLINED,
      isCancellation: false,
      hasBeenRejected: true,
      conflictOverride: true,
    }
  }

  const fetchHolidayRequestDetail = async () => {
    setIsLoading(true)
    await manualRequestsService
      .getHolidayRequestDetail(requestId as string)
      .then(data => {
        setIsLoading(false)
        setRequestDetail(data)
        setInitialRequestDetail(data)
        checkForDates(data.dateTo)
      })
      .catch(err => {
        const response: BaseResponse = err.response.data
        response.errors.forEach(error => {
          dispatch(showErrorMessage(<UserErrorMessage name={error.name} />))
        })
        setIsLoading(false)
        setRequestDetail(null!)
      })
  }

  const declineRequest = async () => {
    setIsLoading(true)
    const body = transformBody()
    if (!body) {
      return
    }
    body.requestActionId = RequestAction.DECLINE
    body.requestStatusId = RequestStatus.DECLINED
    body.hasBeenRejected = true
    await requestsService
      .updateHolidayRequest(body)
      .then(data => {
        setIsLoading(false)
        dispatch(hideModal())
        dispatch(showSuccessMessage(`${occurrenceType} request declined`))
        fetchHolidayRequestDetail()
        navigate(activeRoute || '/myactions')
      })
      .catch(err => {
        const response: BaseResponse = err.response.data
        response.errors.forEach(error => {
          dispatch(showErrorMessage(<UserErrorMessage name={error.name} />))
        })
        setIsLoading(false)
      })
  }

  const approveRequest = async () => {
    setIsLoading(true)
    const body = transformBody()
    if (!body) {
      return
    }
    body.requestActionId = RequestAction.APPROVE
    body.requestStatusId = RequestStatus.APPROVED
    body.hasBeenRejected = false
    await requestsService
      .updateHolidayRequest(body)
      .then(() => {
        setIsLoading(false)
        dispatch(showSuccessMessage(`${occurrenceType} request approved`))
        fetchHolidayRequestDetail()
        navigate(activeRoute || '/myactions')
      })
      .catch(err => {
        const response: BaseResponse = err.response.data
        response.errors.forEach(error => {
          dispatch(showErrorMessage(<UserErrorMessage name={error.name} />))
        })
        setIsLoading(false)
      })
  }

  useEffect(() => {
    fetchHolidayRequestDetail()
  }, [])

  const formattedActionDate =
    requestDetail?.actionedBy && new Date(requestDetail!.actionedDateTime).toLocaleDateString()

  const submitDisabled = () => {
    if (!requestDetail) {
      return true
    }
    return !(
      requestDetail.requestStatusId === RequestStatus.PENDING ||
      requestDetail.requestStatusId === RequestStatus.AMENDED ||
      requestDetail.requestStatusId === RequestStatus.APPROVED_CR ||
      requestDetail.requestStatusId === RequestStatus.DECLINED
    )
  }

  const declineDisabled = () => {
    if (!requestDetail) {
      return true
    }
    return !(
      requestDetail.requestStatusId === RequestStatus.PENDING ||
      requestDetail.requestStatusId === RequestStatus.AMENDED ||
      requestDetail.requestStatusId === RequestStatus.APPROVED_CR ||
      requestDetail.requestStatusId === RequestStatus.APPROVED
    )
  }

  const calculateHours = () => {
    let total = 0
    requestDetail?.requestDays.forEach(day => {
      total += day.checked ? Number(day.hours) : 0
    })
    return total.toFixed(1)
  }

  const getFinancialYearText = () => {
    const formattedDateFrom = requestDetail && new Date(requestDetail.dateFrom).getFullYear()
    const formattedDateTo = requestDetail && new Date(requestDetail.dateTo).getFullYear()
    return financialYearDates?.entitlementPeriod === 'FinancialYear'
      ? `${formattedDateFrom}/${formattedDateTo}`
      : formattedDateFrom
  }

  return (
    <>
      <MyActionsPageHeader title={`${occurrenceType} Request`} />
      <Grid container spacing={4}>
        <Grid item xs={12}>
          {requestDetail && (
            <>
              <InstanceStatusBanner
                showBackButton
                firstname={requestDetail.statusBanner.forename}
                surname={requestDetail.statusBanner.surname}
                status={requestDetail.statusBanner.requestStatus}
              />
              {previousYear && (
                <Alert severity="warning" message="This request is within a previous year" />
              )}
            </>
          )}
        </Grid>
        {requestDetail && (
          <>
            <Grid item xs={12} style={{ display: 'flex', flexFlow: 'column', flex: '1 1 auto' }}>
              <Grid container spacing={4}>
                <Grid
                  item
                  xs={12}
                  xl={4}
                  style={{ display: 'flex', flexFlow: 'column', flex: '1 1 auto' }}
                >
                  <Card
                    title="Requested Hours"
                    icon={<CardTitle title={requestDetail.totalRequestedHours} />}
                  >
                    <Grid container spacing={4}>
                      <Grid item xs={12}>
                        <Stack direction="row" justifyContent="space-between">
                          <Paragraph weight="bold">
                            {financialYearDates?.entitlementPeriod.replace('Year', ' Year')}
                          </Paragraph>
                          <Paragraph>{getFinancialYearText()}</Paragraph>
                        </Stack>
                        <Stack direction="row" justifyContent="space-between">
                          <Paragraph weight="bold">Hours</Paragraph>
                          <Paragraph>{requestDetail.totalRequestedHours}</Paragraph>
                        </Stack>
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          label="Reviewer comments"
                          fullWidth
                          multiline
                          rows={4}
                          disabled
                          defaultValue={requestDetail.reviewerComments}
                        />
                      </Grid>
                    </Grid>
                  </Card>
                </Grid>
                <Grid
                  item
                  xs={12}
                  xl={4}
                  style={{ display: 'flex', flexFlow: 'column', flex: '1 1 auto' }}
                >
                  <Card title="Summary">
                    {entitlementSplit ? (
                      <>
                        <MySummaryEntitlementSplit
                          summaryItems={entitlementSplit}
                          marginTop={0}
                          marginBottom={0}
                          titleMarginBottom={1}
                        />
                      </>
                    ) : (
                      <>
                        <NoDataFound show={!isLoading && !entitlementSplit} />
                        <LoadingIndicator show={isLoading} />
                      </>
                    )}
                  </Card>
                </Grid>
                <Grid
                  item
                  xs={12}
                  xl={4}
                  style={{ display: 'flex', flexFlow: 'column', flex: '1 1 auto' }}
                >
                  <Card title="Approval">
                    <Grid container spacing={4}>
                      <Grid item xs={12}>
                        <TextField
                          label="Reviewer comments"
                          fullWidth
                          multiline
                          rows={2}
                          value={requestDetail.reviewerComments}
                          onChange={e => {
                            setRequestDetail(current => {
                              const { reviewerComments, ...rest } = current as RequestDetailResponse
                              return { reviewerComments: e.target.value, ...rest }
                            })
                          }}
                        />
                      </Grid>
                      {requestDetail.actionedBy && (
                        <Grid
                          item
                          xs={12}
                          display="flex"
                          direction="column"
                          justifyContent="center"
                          rowGap={2}
                        >
                          <Stack direction="row" justifyContent="space-between">
                            <Paragraph weight="bold">Actioned Date</Paragraph>
                            <Paragraph>{formattedActionDate}</Paragraph>
                          </Stack>
                          <Stack direction="row" justifyContent="space-between">
                            <Paragraph weight="bold">Actioned By</Paragraph>
                            <Paragraph>{requestDetail.actionedBy}</Paragraph>
                          </Stack>
                        </Grid>
                      )}
                      <Grid
                        item
                        xs={12}
                        display="flex"
                        justifyContent="flex-end"
                        direction={{ xs: 'column', md: 'row' }}
                      >
                        <Stack
                          direction={{ xs: 'column-reverse', md: 'row' }}
                          justifyContent="space-between"
                          gap={{ xs: 0, md: 4 }}
                        >
                          {requestDetail.requestStatusId !== RequestStatus.APPROVED && (
                            <>
                              <Button
                                label="Decline"
                                variant="outlined"
                                color="secondary"
                                disabled={declineDisabled()}
                                onClick={() => {
                                  displayClashModal()
                                }}
                              />
                              <Button
                                label="Approve"
                                variant="outlined"
                                color="primary"
                                disabled={submitDisabled()}
                                onClick={approveRequest}
                              />
                            </>
                          )}
                        </Stack>
                      </Grid>
                    </Grid>
                  </Card>
                </Grid>
              </Grid>
            </Grid>
          </>
        )}
      </Grid>
      <Modal
        type={type}
        open={showModal}
        onClose={() => {
          dispatch(hideModal())
        }}
        onClick={declineRequest}
        title={title}
        message={message}
        buttonLabel={buttonLabel}
      />
    </>
  )
}

export default AdjustmentOccurrence

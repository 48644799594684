import { GridColDef } from '@mui/x-data-grid'
import { Link } from '@mui/material'
import TypeLabel from '../../../shared/UI/TypeLabel'
import { getPalleteTypeByProp } from '../../../theme/palette'
import { getLocalDateString } from '../../../utils/date-utils'
import StatusLabel from '../../../shared/UI/StatusLabel'
import { EnhancementStub, SubmitStatus } from '../../../models/enhancement'

const testid = 'enhancement-summary-'

function headerText(testId: string, headerName?: string) {
  return (
    <div style={{ fontWeight: 'bold', color: 'black' }} data-testid={testId}>
      {headerName}
    </div>
  )
}
const calculateStatusLabel = (row: EnhancementStub) => {
  switch(row.submitStatus) {
    case SubmitStatus.APPROVED:
    case SubmitStatus.AMENDED:
      return 'Pending Submission'
    case SubmitStatus.SUBMITTED:
      return 'Submitted'
    default:
      return row.submitStatus
  }
}

export const EnhancementColumns: GridColDef[] = [
  {
    field: 'displayName',
    headerName: 'Employee',
    headerClassName: 'header',
    flex: 1.5,
    renderHeader: params => headerText(params.field, params.colDef.headerName),
    renderCell: params => (
      <div data-testid={`${testid}row-${params.row.enhancementId}`}>
        <Link component="button" variant="body2">
          {params.value}
        </Link>
      </div>
    ),
  },
  {
    field: 'enhancementType',
    headerName: 'Type',
    headerClassName: 'header',
    flex: 1,
    renderHeader: params => headerText(params.field, params.colDef.headerName),
    renderCell: params => (
      <div data-testid={`cell-enhancementType-${params.row.id}`}>
        <TypeLabel label={getPalleteTypeByProp(params.value).label} type={params.value} />
      </div>
    ),
  },
  {
    field: 'hours',
    headerName: 'Hours',
    headerClassName: 'header',
    flex: 0.5,
    renderHeader: params => headerText(params.field, params.colDef.headerName),
    renderCell: params => <div data-testid={`cell-hours-${params.row.id}`}>{params.value}</div>,
  },
  {
    field: 'comments',
    headerName: 'Comments',
    headerClassName: 'header',
    flex: 2,
    renderHeader: params => headerText(params.field, params.colDef.headerName),
    renderCell: params => <div data-testid={`cell-comments-${params.row.id}`}>{params.value}</div>,
  },
  {
    field: 'approvedBy',
    headerName: 'Approved By',
    headerClassName: 'header',
    flex: 1.5,
    renderHeader: params => headerText(params.field, params.colDef.headerName),
    renderCell: params => (
      <div data-testid={`cell-approvedBy-${params.row.id}`}>{params.value}</div>
    ),
  },
  {
    field: 'approvalDate',
    headerName: 'Approval Date',
    headerClassName: 'header',
    flex: 1,
    renderHeader: params => headerText(params.field, params.colDef.headerName),
    renderCell: params => (
      <div data-testid={`cell-approvalDate-${params.row.id}`}>
        {params.row.approvalDate ? getLocalDateString(new Date(params.row.approvalDate)) : ''}
      </div>
    ),
    sortable: true,
  },
  {
    field: 'submitStatus',
    headerName: 'Status',
    headerClassName: 'header',
    flex: 1,
    renderHeader: params => headerText(params.field, params.colDef.headerName),
    renderCell: params => (
      <div data-testid={`cell-submitStatus-${params.row.id}`}>
        <StatusLabel status={calculateStatusLabel(params.row)} />
      </div>
    ),
  },
]

export const EnhancementMobileColumns: GridColDef[] = [
  {
    field: 'displayName',
    headerName: 'Employee',
    headerClassName: 'header',
    width: 130,
    minWidth: 130,
    renderHeader: params => headerText(params.field, params.colDef.headerName),
    renderCell: params => (
      <div data-testid={`cell-displayName-${params.row.id}`}>
        <Link component="button" variant="body2">
          {params.value}
        </Link>
      </div>
    ),
  },
  {
    field: 'hours',
    headerName: 'Hours',
    headerClassName: 'header',
    width: 130,
    minWidth: 130,
    renderHeader: params => headerText(params.field, params.colDef.headerName),
    renderCell: params => <div data-testid={`cell-hours-${params.row.id}`}>{params.value}</div>,
  },
  {
    field: 'enhancementType',
    headerName: 'Type',
    headerClassName: 'header',
    width: 130,
    minWidth: 130,
    renderHeader: params => headerText(params.field, params.colDef.headerName),
    renderCell: params => (
      <div data-testid={`cell-enhancementType-${params.row.id}`}>
        <TypeLabel label={getPalleteTypeByProp(params.value).label} type={params.value} />
      </div>
    ),
  },
  {
    field: 'comments',
    headerName: 'Comments',
    headerClassName: 'header',
    width: 130,
    minWidth: 130,
    renderHeader: params => headerText(params.field, params.colDef.headerName),
    renderCell: params => <div data-testid={`cell-comments-${params.row.id}`}>{params.value}</div>,
  },
  {
    field: 'approvedBy',
    headerName: 'Approved By',
    headerClassName: 'header',
    width: 130,
    minWidth: 130,
    renderHeader: params => headerText(params.field, params.colDef.headerName),
    renderCell: params => (
      <div data-testid={`cell-approvedBy-${params.row.id}`}>{params.value}</div>
    ),
  },
  {
    field: 'approvalDate',
    headerName: 'Approval Date',
    headerClassName: 'header',
    width: 130,
    minWidth: 130,
    renderHeader: params => headerText(params.field, params.colDef.headerName),
    renderCell: params => (
      <div data-testid={`cell-approvalDate-${params.row.id}`}>
        {params.row.approvalDate ? getLocalDateString(new Date(params.row.approvalDate)) : ''}
      </div>
    ),
    sortable: true,
  },
  {
    field: 'submitStatus',
    headerName: 'Status',
    headerClassName: 'header',
    width: 130,
    minWidth: 130,
    renderHeader: params => headerText(params.field, params.colDef.headerName),
    renderCell: params => (
      <div data-testid={`cell-submitStatus-${params.row.id}`}>
        <StatusLabel status={calculateStatusLabel(params.row)} />
      </div>
    ),
  },
]

const bannerStylesConfig = {
  closed: {
    bgColor: '#f5f5f5',
    textColor: '#616161',
    chevronColor: '#616161',
  },
  current: {
    bgColor: '#F2FFF2',
    textColor: '#616161',
    chevronColor: '#1E4620',
  },
  next: {
    bgColor: '#FFF9EA',
    textColor: '#616161',
    chevronColor: '#A27403',
  }
}

const bannerStyles = (bgColor: string) => ({
  padding: '16px',
  display: 'flex',
  justifyContent: 'space-between',
  backgroundColor: bgColor,
  boxShadow: '1px 2px 10px #00000015',
  marginBottom: '16px',
  borderRadius: '16px',
})

const enabledChevronStyles = (chevronColor: string) => ({
  color: chevronColor,
})
const disabledChevronStyles = {
  color: '#1E462034',
}

export const getComponentStyles = (status: keyof typeof bannerStylesConfig) => {
  const { bgColor, textColor, chevronColor } = bannerStylesConfig[status]

  return {
    disabledChevronStyles,
    enabledChevronStyles: enabledChevronStyles(chevronColor),
    bannerStyles: bannerStyles(bgColor),
    textColor,
    bgColor,
  }
}

export const contentStyles = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '100%',
  padding: '0px 16px'
}


import { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { Grid, Stack, TextField } from '@mui/material'
import { format } from 'date-fns'

import { DateRange } from '@mui/x-date-pickers-pro'
import { StyledGrid } from '../../OccurrenceRequest'
import {
  EnhancementDay,
  EnhancementGetResponse,
  StatusResponse,
  SubmitStatus,
} from '../../../models/enhancement'
import Button from '../../../shared/UI/Button'
import DaysCheckboxList from '../DaysCheckboxList/DaysCheckboxList'
import Card from '../../../shared/layout/Card'
import { hasDaysChanged } from '../hasDaysChanged'
import Modal from '../../../shared/UI/Modal'
import { RootStore, useAppDispatch } from '../../../redux/store'
import {
  SetShowModalPayload,
  hideModal,
  showModalDialog,
} from '../../../redux/reducers/appSettingsReducer'
import DateTimePicker from '../../../shared/UI/DateTimePicker'
import {
  callOutFromContent,
  enhancementContent,
  enhancementTypes,
  globalContent,
  ON_CALL_HOURS,
} from '../../../utils/constants'
import { ValidationErrors } from '../../CallOutEnhancement/validations'
import {
  formatDateWithTimeZoneStr,
  getDurationInFractionalHours,
  getLocalDateString,
} from '../../../utils/date-utils'
import Alert from '../../../shared/UI/Alert/Alert'

import { DateFormats } from '../../../api/absence/tempTypes/generalprops'
import { v2MyActionsService } from '../../../services/myActionsServiceV2'
import { showErrorMessage, showSuccessMessage } from '../../../redux/reducers/snackbarReducer'
import {
  disableDeclineEnhancement,
  isReadOnlyForEmployeeEnhancement,
  isReadOnlyForManagerEnhancement,
} from '../../../utils/SharedMethods/isReadOnlyEnhancement'
import {
  calculateCalloutDays,
} from '../../../utils/SharedMethods/calculateCallOutDays'
import { latestApproverComment } from '../../../utils/SharedMethods/getCommentsFromStatusHistory'
import { calculateTotalHours } from '../../MyRequests/utils'
import Paragraph from '../../../shared/UI/Paragraph'

export default function Approval({
  days,
  id,
  dateRange,
  enhancementType,
  statusHistory,
  currentStatus,
  setTotalHours,
  onStatusChangeSuccess,
}: EnhancementGetResponse & {
  onStatusChangeSuccess: () => void
  setTotalHours: (hours: string) => void
}) {
  const dispatch = useAppDispatch()
  const [requestedDays, setRequestedDays] = useState<EnhancementDay[]>(
    JSON.parse(JSON.stringify(days!))
  )
  const [comments, setComments] = useState<string>(latestApproverComment(statusHistory))
  const [commentsError, setCommentsError] = useState<boolean>()
  const [requestedDateRange, setRequestedDateRange] = useState<DateRange<Date>>([
    new Date(dateRange.start),
    new Date(dateRange.end),
  ])
  const [formErrors, setFormErrors] = useState<ValidationErrors>()
  const { showModal, title, message, type, buttonLabel } = useSelector<
    RootStore,
    SetShowModalPayload
  >((state: RootStore) => state.appSettings.modalProps)

  const isCalloutEnhancment = enhancementType === enhancementTypes.callOut
  const isReadOnlyRequest = isReadOnlyForManagerEnhancement(currentStatus!)

  const actionedHistoryStatus = useMemo(
    () => statusHistory.filter(item => item.submitStatus !== SubmitStatus.PENDING),
    [statusHistory]
  )
  const GetMostRecentHistoryStatus = useCallback(
    (a: StatusResponse, b: StatusResponse) => (new Date(a.date) > new Date(b.date) ? a : b),
    []
  )
  const mostRecentAction: StatusResponse | undefined = useMemo(
    () =>
      actionedHistoryStatus.length
        ? actionedHistoryStatus.reduce(GetMostRecentHistoryStatus)
        : undefined,
    [GetMostRecentHistoryStatus, actionedHistoryStatus]
  )

  const resetForm = () => {
    setRequestedDateRange([new Date(dateRange.start), new Date(dateRange.end)])
    setRequestedDays(JSON.parse(JSON.stringify(days!)))
  }

  useEffect(() => resetForm(), [dateRange, days])

  useEffect(() => {
    /**
     * Check if the call out duration is valid
     */
    const validateCalloutDuration = () => {
      const [start, end] = requestedDateRange
      if (start && end) {
        // TODO [SWKBSSPRPS-797]: Validate that end time is greater than start time
        setFormErrors(prevState => ({
          ...prevState,
          start: false,
          end: false,
        }))
      }
    }
    if (isCalloutEnhancment && requestedDateRange[0] && requestedDateRange[1]) {
      validateCalloutDuration()
      const allDays = calculateCalloutDays(requestedDateRange)
      setRequestedDays(
        allDays.map(day => ({
          day: formatDateWithTimeZoneStr(day.day),
          hours: day.hours,
          checked: day.checked,
        }))
      )
    }
  }, [requestedDateRange])

  const handleDaysChange = (updatedDays: EnhancementDay[]) => {
    const ehnacementDays = [...updatedDays]
    const hours = calculateTotalHours(ehnacementDays)
    setFormErrors(prevState => ({
      ...prevState,
      duration: hours <= 0,
    }))

    setTotalHours(hours.toFixed(2))
    setRequestedDays(ehnacementDays)
  }

  const handleCallOutTimePickerChange = (toDate: Date | null, fromDate: Date | null) => {
    if (!toDate || !fromDate) return
    setTotalHours(getDurationInFractionalHours(toDate, fromDate).toFixed(2))
    setRequestedDateRange([toDate, fromDate])
  }

  const hasDateRangeChanged =
    requestedDateRange[0]?.getTime() !== new Date(dateRange.start).getTime() ||
    requestedDateRange[1]?.getTime() !== new Date(dateRange.end).getTime()

  const validateDateRange = () => {
    if (!requestedDateRange[0] || !requestedDateRange[1]) {
      setFormErrors(prevState => ({
        ...prevState,
        start: !requestedDateRange[0],
        end: !requestedDateRange[1],
      }))
      return false
    }
  }

  const hasChanges = () => {
    if (isCalloutEnhancment) return hasDateRangeChanged
    return hasDaysChanged(days!, requestedDays)
  }
  const buildApprovalBody = (submitStatus: SubmitStatus) => {
    const body = {
      id,
      days,
      dateRange,
      status: {
        submitStatus,
        comments,
      },
    }
    if (submitStatus === SubmitStatus.DECLINED) {
      resetForm()
      return body
    }
    return {
      ...body,
      days: requestedDays,
      dateRange: {
        start: format(requestedDateRange[0]!, DateFormats.DATE_AND_TIME),
        end: format(requestedDateRange[1]!, DateFormats.DATE_AND_TIME),
      },
    }
  }
  const putRequest = (submitStatus: SubmitStatus, messageText: string) => {
    validateDateRange()
    v2MyActionsService
      .putEnhancementManual(buildApprovalBody(submitStatus))
      .then(() => {
        dispatch(showSuccessMessage(`Request successfully ${messageText}`))
        onStatusChangeSuccess()
      })
      .catch(() => {
        dispatch(showErrorMessage(`Failed request has not been ${messageText}`))
      })
      .finally(() => {
        dispatch(hideModal())
      })
  }

  const declineRequest = () => {
    putRequest(SubmitStatus.DECLINED, 'declined')
  }

  const approveRequest = () => {
    if (hasChanges() && !comments) return setCommentsError(!comments)

    if (hasChanges()) putRequest(SubmitStatus.AMENDED, 'amended')
    else putRequest(SubmitStatus.APPROVED, 'approved')
  }

  const displayClashModal = (e: string) => {
    if (!comments) return setCommentsError(!comments)
    dispatch(
      showModalDialog({
        title: 'Are you sure?',
        message: `Are you sure you would like to decline this request?`,
        buttonLabel: 'Decline',
        type: 'question',
        showModal: true,
      })
    )
  }

  const handleCommentChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setComments(e.target.value)
    if (e.target.value) {
      setCommentsError(false)
    }
  }

  const isButtonDisabled = (): boolean | undefined =>
    (isReadOnlyForEmployeeEnhancement(currentStatus!) && !hasChanges()) ||
    formErrors?.duration

  return (
    <Card title="Approval" testId="enhancement-approval-form">
      <Grid container spacing={4}>
        {isCalloutEnhancment ? (
          <>
            <StyledGrid
              item
              xs={12}
              sx={{
                overflowY: 'scroll',
                maxHeight: '250px',
                marginTop: '20px',
              }}
            >
              <Grid item xs={12}>
                <DateTimePicker
                  value={requestedDateRange[0]}
                  maxDate={new Date()}
                  onChange={date => handleCallOutTimePickerChange(date, requestedDateRange[1])}
                  label={globalContent.from}
                  minutesStep={5}
                  error={formErrors?.start}
                  helperText={globalContent.required}
                  dataTestId="callout-from-date"
                  disabled={isReadOnlyRequest}
                />
              </Grid>
              <Grid item xs={12} mt={2}>
                <DateTimePicker
                  value={requestedDateRange[1]}
                  maxDate={new Date()}
                  onChange={date => handleCallOutTimePickerChange(requestedDateRange[0], date)}
                  label={globalContent.to}
                  minutesStep={5}
                  error={formErrors?.end}
                  helperText={globalContent.required}
                  dataTestId="callout-to-date"
                  disabled={isReadOnlyRequest}
                />
              </Grid>
            </StyledGrid>
          </>
        ) : (
          <>
            <StyledGrid
              item
              xs={12}
              sx={{
                overflowY: 'scroll',
                maxHeight: '250px',
                marginTop: '20px',
              }}
            >
              <Grid>
                <DaysCheckboxList
                  days={requestedDays}
                  disableCheckbox={isReadOnlyRequest}
                  disableTextField={isReadOnlyRequest}
                  onDaysChange={updatedDays => {
                    handleDaysChange(updatedDays)
                  }}
                />
              </Grid>
            </StyledGrid>
            <Grid item xs={12}>
              {formErrors?.duration ? (
                <Alert severity="error" message={enhancementContent.zeroHoursValidation} />
              ) : null}
            </Grid>
          </>
        )}

        <Grid item xs={12}>
          <Stack direction="row">
            <TextField
              label="Reviewer comments"
              fullWidth
              multiline
              rows={4}
              value={comments}
              sx={{ paddingRight: '6px' }}
              onChange={e => handleCommentChange(e)}
              error={commentsError}
              disabled={isReadOnlyRequest}
            />
          </Stack>
          <br />
          {mostRecentAction && (
            <div key={mostRecentAction.date.toString()}>
              <Stack direction="row" justifyContent="space-between">
                <Paragraph weight="bold">Actioned Date</Paragraph>
                <Paragraph>{getLocalDateString(new Date(mostRecentAction.date))}</Paragraph>
              </Stack>
              <Stack direction="row" justifyContent="space-between">
                <Paragraph weight="bold">Actioned By</Paragraph>
                <Paragraph>{mostRecentAction.approver?.employeeDetails.displayName}</Paragraph>
              </Stack>
            </div>
          )}
        </Grid>
        <Grid
          item
          xs={12}
          sx={{
            paddingRight: '6px',
            '&.MuiGrid-item': {
              paddingTop: '0px',
            },
          }}
        >
          <Stack
            direction={{ xs: 'column-reverse', md: 'row' }}
            justifyContent="space-between"
            gap={{ xs: 0, md: 4 }}
          >
            <Button
              label="Decline"
              variant="outlined"
              color="secondary"
              onClick={() => displayClashModal(enhancementType)}
              dataTestId="decline-button"
              disabled={disableDeclineEnhancement(currentStatus!)}
            />
            <Button
              label={hasChanges() ? 'Approve With Changes' : 'Approve'}
              variant="outlined"
              color="primary"
              onClick={approveRequest}
              disabled={isButtonDisabled()}
              dataTestId="approve-button"
            />
          </Stack>
        </Grid>
      </Grid>
      <Modal
        type={type}
        open={showModal}
        onClose={() => {
          dispatch(hideModal())
        }}
        onClick={declineRequest}
        title={title}
        message={message}
        buttonLabel={buttonLabel}
      />
    </Card>
  )
}

import { useCallback } from 'react'
import ClearIcon from '@mui/icons-material/Clear'
import { Box } from '@mui/material'
import { useSelector } from 'react-redux'
import { RootStore, useAppDispatch } from '../../../redux/store'
import TypeLabel from '../../../shared/UI/TypeLabel'
import { toggleRequestTypeCheckbox } from '../../../redux/reducers/myActionsReducer'

interface FilterChipProps {
  type: string
  displayName: string
}

function FilterChip({ type, displayName }: FilterChipProps) {
  const dispatch = useAppDispatch()
  const removeFilter = useCallback(() => {
    dispatch(toggleRequestTypeCheckbox(type))
  }, [])

  return (
    <span style={{ display: 'inline-block', margin: '0 8px 8px 0' }} key={displayName}>
      <TypeLabel
        label={type}
        testId={displayName.replace(/\s/g, '')}
        type={type}
        deleteIcon={<ClearIcon />}
        onDelete={removeFilter}
      />
    </span>
  )
}

function FilterChips() {
  const { filterStates } = useSelector((state: RootStore) => state.myActions)
  if (!filterStates.checkboxSettings?.length) return null
  return (
    <Box mx={5} display={{ xs: 'none', md: 'inline' }}>
      {filterStates.checkboxSettings.map(
        filter =>
          filter.checked && (
            <FilterChip key={filter.type} type={filter.type} displayName={filter.displayName} />
          )
      )}
    </Box>
  )
}

export default FilterChips

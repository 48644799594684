/**
 * A radio group to confirm an action
 */
import { FormControl, RadioGroup, FormControlLabel, Radio, Typography } from '@mui/material'
import React from 'react'
import { Info } from '@mui/icons-material'
import { CustomWidthTooltip } from '../../../shared/UI/CustomToolTip/CustomTooltip'

interface RowRadioGroupProps {
  label: string
  value: boolean
  tooltipText?: string
  testid?: string
  disabled?: boolean
  onRadioChange: (newValue: boolean) => void
}

export const styles = {
  display: 'flex',
  flexDirection: { xs: 'column', sm: 'row' },
  justifyContent: 'space-between',
  alignItems: { xs: 'start', md: 'center' },
  margin: '16px 0 -24px 32px',
  marginBottom: { xs: 0, sm: '-24px' },
  width: '100%',
}
const options = [
  {
    value: true,
    label: 'Yes',
  },
  {
    value: false,
    label: 'No',
  },
]

export function RowRadioGroup({
  label,
  value,
  onRadioChange,
  tooltipText,
  disabled,
  testid = 'radio-group',
}: RowRadioGroupProps) {
  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const option = event.target.value === 'true'
    onRadioChange(option)
  }

  return (
    <FormControl sx={styles}>
      <Typography style={{ display: 'flex', gap: '4px' }}>
        {label}
        {tooltipText ? (
          <CustomWidthTooltip title={tooltipText} data-testid={`${testid}-tooltip`}>
            <Info fontSize="small" sx={{ color: '#20C5A0' }} />
          </CustomWidthTooltip>
        ) : null}
      </Typography>
      <RadioGroup row value={value} onChange={handleOnChange} data-testid={testid}>
        {options.map(option => (
          <FormControlLabel
            value={option.value}
            control={<Radio />}
            label={option.label}
            key={option.label}
            disabled={disabled}
          />
        ))}
      </RadioGroup>
    </FormControl>
  )
}

import { Grid } from '@mui/material'
import { ReactElement } from 'react'
import CardTitle from '../../UI/CardTitle'
import { globalContent } from '../../../utils/constants'
import Card from '../../layout/Card'
import Paragraph from '../../UI/Paragraph'
import { formatDateTimeWithLocale, formatDateWithLocale } from '../../../utils/date-utils'


interface RequestDatesCardProps {
  title: string
  totalHours: string
  dateFrom: string
  dateTo: string
  dateSubmitted: string | undefined
  // whether date from/to should display both date and time
  showFullTimestamp: boolean
  extraItems?: ReactElement[]
}

export function RequestDatesCard({ title, totalHours, dateFrom, dateTo, dateSubmitted, showFullTimestamp, extraItems }: RequestDatesCardProps) {
  const formatRequestedDate = showFullTimestamp ? formatDateTimeWithLocale : formatDateWithLocale
  return (
    <Card title={title} testId="request-dates" icon={<CardTitle title={totalHours} />}>
      <Grid container textAlign="left" spacing={4}>
        <Grid container item direction="column" xs={12} spacing={1}>
          <Grid container item columnSpacing={1} columns={14}>
            <Grid item xs={4}>
              <Paragraph weight="bold">{globalContent.dateFrom}</Paragraph>
            </Grid>
            <Grid item xs={4}>
              <Paragraph>{formatRequestedDate(dateFrom)}</Paragraph>
            </Grid>
            <Grid item xs={2}>
              <Paragraph weight="bold">{globalContent.dateTo}</Paragraph>
            </Grid>
            <Grid item xs={4}>
              <Paragraph>{formatRequestedDate(dateTo)}</Paragraph>
            </Grid>
          </Grid>
          <Grid container item columnSpacing={1} columns={14}>
            <Grid item xs={4}>
              <Paragraph weight="bold">{globalContent.dateSubmitted}</Paragraph>
            </Grid>
            <Grid item xs={4}>
              <Paragraph>{dateSubmitted ? formatDateTimeWithLocale(dateSubmitted) : ''}</Paragraph>
            </Grid>
          </Grid>
        </Grid>
        { extraItems && extraItems.map((item) => (
          <Grid key={item.key} item xs={12}>
            {item}
          </Grid>
        ))}
      </Grid>
    </Card>
  )
}

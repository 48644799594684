import { TextField } from '@mui/material'
import { EnhancementGetResponse, SubmitStatus } from '../../../models/enhancement'
import { globalContent } from '../../../utils/constants'
import { findLatestCommentByStatus } from '../../../utils/SharedMethods/getCommentsFromStatusHistory'
import { RequestDatesCard } from '../../../shared/layout/RequestDatesCard'
import { getDateSubmittedFromStatusHistory } from '../../../utils/SharedMethods/getDateSubmittedFromStatusHistory'


export function TotalHoursCard({
  dateRange,
  statusHistory,
  totalHours,
}: EnhancementGetResponse & { totalHours: string }) {
  const displayTotalHours =
    Number(totalHours) > 0 ? Number(totalHours).toFixed(2).toString() : '0.00'
  const comment = () => findLatestCommentByStatus(statusHistory, SubmitStatus.PENDING)
  const dateSubmitted = getDateSubmittedFromStatusHistory(statusHistory)
  const comments = 
    <TextField
      label={globalContent.requesterComments}
      fullWidth
      multiline
      rows={4}
      disabled
      value={comment()}
      data-testid="description"
    />

  return (
    <RequestDatesCard 
      title={globalContent.totalHours}
      totalHours={displayTotalHours}
      dateFrom={dateRange.start}
      dateTo={dateRange.end}
      dateSubmitted={dateSubmitted}
      showFullTimestamp={false}
      extraItems={[comments]}
    />
  )
}

import { MyActionsFilterState } from '../../components/MyActions/MyActionsFilter/Types'
import { SelectOption } from '../../services/dashboardService'
import { MyActionsSelectedStatus } from '../../components/MyActionsGrid/Shared/enums'
import { FlattenedRequest } from '../../types/flattened-requests'

const myActionsFilterState: MyActionsFilterState = {
  checkboxSettings: [],
  requestParams: {
    directReportees: true,
    dateFrom: new Date(),
    dateTo: new Date(),
    requestStatusId: MyActionsSelectedStatus.AWAITING_ACTION,
    enhancementsOnly: false
  },
  requestParamsDefaults: {
    directReportees: true,
    dateFrom: new Date(),
    dateTo: new Date(),
    requestStatusId: MyActionsSelectedStatus.AWAITING_ACTION,
    enhancementsOnly: false
  },
  selectionState: {
    team: {
      teamId: 0,
      teamName: '',
      departmentId: undefined,
    },
    employees: null,
    status: MyActionsSelectedStatus.AWAITING_ACTION,
    department: {
      value: 0,
      displayValue: '',
    },
    dateRange: [null, null],
    directReportees: true,
  },
  selectionStateDefault: {
    team: {
      teamId: 0,
      teamName: '',
      departmentId: undefined,
    },
    employees: null,
    status: MyActionsSelectedStatus.AWAITING_ACTION,
    department: {
      value: 0,
      displayValue: '',
    },
    dateRange: [null, null],
    directReportees: true,
  },
  metaData: { initialised: false },
}

export interface MyActionsState {
  selectedDepartmentFilter: SelectOption | null | undefined
  filterStates: MyActionsFilterState
}

export const myActionsInitialState: MyActionsState = {
  selectedDepartmentFilter: null,
  filterStates: myActionsFilterState,
}

const SET_SELECTED_DEPARTMENT = 'SET_SELECTED_DEPARTMENT'
const SET_FILTER_STATES = 'SET_FILTER_STATES'
const TOGGLE_REQUEST_TYPE_CHECKBOX = 'TOGGLE_REQUEST_TYPE_CHECKBOX'
const RESET_FILTERS = 'RESET_FILTERS'
const SET_ENHANCEMENTS_ONLY_TOGGLE = 'SET_ENHANCEMENTS_ONLY_TOGGLE'
const SET_CHECKBOX_COUNTS = 'SET_CHECKBOX_COUNTS'

interface SetSelectedDepartmentFilterAction {
  type: typeof SET_SELECTED_DEPARTMENT
  payload: SelectOption | null | undefined
}

interface SetFilterStatesAction {
  type: typeof SET_FILTER_STATES
  payload: MyActionsFilterState
}

interface ToggleRequestTypeCheckboxAction {
  type: typeof TOGGLE_REQUEST_TYPE_CHECKBOX
  payload: string
}

interface ResetFiltersAction {
  type: typeof RESET_FILTERS
}

interface SetEnhancementsOnlyToggleAction {
  type: typeof SET_ENHANCEMENTS_ONLY_TOGGLE
  payload: boolean
}

interface SetCheckboxCountsAction {
  type: typeof SET_CHECKBOX_COUNTS
  payload: FlattenedRequest[]
}

type myActionsDispatchtypes = SetSelectedDepartmentFilterAction 
  | SetFilterStatesAction
  | ToggleRequestTypeCheckboxAction
  | ResetFiltersAction
  | SetEnhancementsOnlyToggleAction
  | SetCheckboxCountsAction

// Actions
export const setSelectedDepartmentFilter = (
  departmentId: SelectOption | null | undefined
): SetSelectedDepartmentFilterAction => ({
  type: SET_SELECTED_DEPARTMENT,
  payload: departmentId,
})

export const setFilterStates = (filterState: MyActionsFilterState): SetFilterStatesAction => ({
  type: SET_FILTER_STATES,
  payload: filterState,
})

export const toggleRequestTypeCheckbox = (requestType: string): ToggleRequestTypeCheckboxAction => ({
  type: TOGGLE_REQUEST_TYPE_CHECKBOX,
  payload: requestType
})

export const resetFilters = (): ResetFiltersAction => ({
  type: RESET_FILTERS
})

export const setEnhancementsOnlyToggle = (toggleValue: boolean): SetEnhancementsOnlyToggleAction => ({
  type: SET_ENHANCEMENTS_ONLY_TOGGLE,
  payload: toggleValue
})

export const setCheckboxCount = (requests: FlattenedRequest[]): SetCheckboxCountsAction => ({
  type: SET_CHECKBOX_COUNTS,
  payload: requests
})

// Reducer
export const myActionsReducer = (
  state: MyActionsState = myActionsInitialState,
  action: myActionsDispatchtypes
): MyActionsState => {
  switch (action.type) {
    case SET_SELECTED_DEPARTMENT:
      return { ...state, selectedDepartmentFilter: action.payload }
    case SET_FILTER_STATES:
      return { ...state, filterStates: action.payload }
    case TOGGLE_REQUEST_TYPE_CHECKBOX:
      return {
        ...state,
        filterStates: 
        {
          ...state.filterStates,
          checkboxSettings: state.filterStates.checkboxSettings.map(
            (setting) => ({
            ...setting,
            checked: setting.type === action.payload ? !setting.checked : setting.checked
          }))
        }
      }
    case RESET_FILTERS:
      return {
        ...state,
        filterStates: {
          ...state.filterStates,
          checkboxSettings: state.filterStates.checkboxSettings.map(setting => ({ ...setting, checked: false })),
          selectionState: { ...state.filterStates.selectionStateDefault },
          requestParams: { ...state.filterStates.requestParamsDefaults },
          metaData: { initialised: false }
        }
      }
    case SET_ENHANCEMENTS_ONLY_TOGGLE:
      return {
        ...state,
        filterStates: {
          ...state.filterStates,
          requestParams: { ...state.filterStates.requestParams, enhancementsOnly: action.payload }
        }
      }
    case SET_CHECKBOX_COUNTS:
      return {
        ...state,
        filterStates: {
          ...state.filterStates,
          checkboxSettings: state.filterStates.checkboxSettings.map(setting => {
            const count = action.payload.filter(request => request.requestType === setting.type).length
            return {
              ...setting,
              count,
              checked: count === 0 ? false : setting.checked
            }
          })
        }
      }
    default:
      return state
  }
}

import { StatusResponse, SubmitStatus } from "../../models/enhancement"

export const getDateSubmittedFromStatusHistory = (statusHistory: StatusResponse[]): string | undefined => {
  for (let i = statusHistory.length - 1; i>=0; i -= 1) {
    // manual enhancements bypass 'PENDING' status, so for them return the earlier status date
    if (statusHistory[i].submitStatus === SubmitStatus.PENDING || i === 0) {
      return statusHistory[i].date
    }
  }

  return undefined
}
import { Link, useMediaQuery } from '@mui/material'
import { DataGrid, GridColDef } from '@mui/x-data-grid'
import { useState } from 'react'
import StatusLabel from '../../shared/UI/StatusLabel'

import { getLocalDateString } from '../../utils/date-utils'
import { HolidaySearchGridProps } from './types'

import { isMobilePortrait } from '../../theme/deviceChecks'
import TypeLabel from '../../shared/UI/TypeLabel'
import NoResult from '../../shared/UI/NoResult'
import { HolidaySearchItem } from '../../types/holiday-search'

function NoRowsOverlay() {
  return <NoResult message="No Results" showImage />
}

function HolidaySearchGrid({ data, onViewHistory }: HolidaySearchGridProps) {
  const [pageSize, setPageSize] = useState<number>(10)
  const mobilePortrait = useMediaQuery(isMobilePortrait())

  const getType = (requestType: string) => <TypeLabel label={requestType} type={requestType} />

  const calculateCellValuesStatusLabel = (cellValues: HolidaySearchItem) => {
    if (!cellValues.requestStatus) return

    if (cellValues.isQueried) {
      return 'Queried'
    }
    return cellValues.requestStatus
  }

  const columns: GridColDef[] = [
    {
      field: 'employeeName',
      headerName: 'Employee',
      headerClassName: 'header',
      flex: 1,
      renderCell: params => (
        <Link
          component="button"
          variant="body2"
          onClick={() => {
            onViewHistory(params.row.requestId, params.row.employeeName)
          }}
        >
          {params.row.employeeName}
        </Link>
      ),
    },
    {
      field: 'type',
      headerName: 'Type',
      headerClassName: 'header',
      type: 'string',
      flex: 1,
      renderCell: cellValues => getType(cellValues.row.type),
    },
    {
      field: 'dateFrom',
      headerName: 'Date from',
      headerClassName: 'header',
      type: 'date',
      flex: 1,
      renderCell: cellValues => getLocalDateString(cellValues.value),
    },
    {
      field: 'dateTo',
      headerName: 'Date to',
      headerClassName: 'header',
      type: 'date',
      flex: 1,
      renderCell: cellValues => cellValues.value && getLocalDateString(cellValues.value),
    },
    {
      field: 'hours',
      headerName: 'Hours',
      headerClassName: 'header',
      flex: 1,
      renderCell: (cellValues) => {
        const formattedHours = cellValues.value === undefined ? '-' : cellValues.value
        return (
          <span data-testid={`cell-${cellValues.id}-hours`}>
            {formattedHours}
          </span>
        )
      }
    },
    {
      field: 'requestStatus',
      headerName: 'Status',
      headerClassName: 'header',
      flex: 1,
      renderCell: cellValues => (
        <StatusLabel
          status={
            cellValues.row.isCancellation
              ? 'Cancellation Requested'
              : calculateCellValuesStatusLabel(cellValues.row)
          }
        />
      ),
    },
    {
      field: 'lastActionedBy',
      headerName: 'Last Actioned By',
      headerClassName: 'header',
      flex: 1,
      renderCell: cellValues => cellValues.value,
    },
  ]

  const mobileColumns: GridColDef[] = [
    {
      field: 'employeeName',
      headerName: 'Employee',
      headerClassName: 'header',
      flex: 1,
      renderCell: params => (
        <Link
          component="button"
          variant="body2"
          onClick={() => {
            onViewHistory(params.row.requestId, params.row.employeeName)
          }}
        >
          {params.row.employeeName}
        </Link>
      ),
    },
    {
      field: 'type',
      headerName: 'Type',
      headerClassName: 'header',
      type: 'string',
      flex: 1,
      renderCell: cellValues => getType(cellValues.row.type),
    },
    {
      field: 'requestStatus',
      headerName: 'Status',
      headerClassName: 'header',
      flex: 1,
      renderCell: cellValues => <StatusLabel status={cellValues.row.requestStatus} />,
    },
  ]

  return (
    <>
      <DataGrid
        getRowId={row => row.requestId}
        rows={data}
        columns={mobilePortrait ? mobileColumns : columns}
        disableSelectionOnClick
        rowsPerPageOptions={[10, 25, 50, 100]}
        pageSize={pageSize}
        onPageSizeChange={newPageSize => setPageSize(newPageSize)}
        rowHeight={50}
        autoHeight
        components={{ NoRowsOverlay }}
        initialState={{
          sorting: {
            sortModel: [{ field: 'requestId', sort: 'asc' }],
          },
        }}
        sx={{
          '&.MuiDataGrid-root': {
            border: 'none',
            paddingTop: '40px',
          },
          '& .MuiDataGrid-virtualScrollerContent': {
            minHeight: '200px!important',
          },
          '.header': {
            backgroundColor: 'rgb(247, 247, 247)',
          },
        }}
        componentsProps={{
          panel: {
            sx: {
              '& .MuiNativeSelect-select option': {
                font: '13px "Poppins", sans-serif',
              },
            },
          },
        }}
      />
    </>
  )
}

export default HolidaySearchGrid

import { LoginCallback } from '@okta/okta-react'
import { Route, Routes } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { RootStore } from '../redux/store'
import Absence from '../components/Absence'
import AdjustmentOccurrence from '../components/AdjustmentOccurrenceRequest'
import BuySellRequest from '../components/BuySellRequest'
import Holiday from '../components/HolidayReports'
import Late from '../components/Late'
import ManualOccurrenceRequest from '../components/ManualOccurrenceRequest'
import MyActions from '../components/MyActions/MyActions'
import MyExtras from '../components/MyExtras'
import MyRequests from '../components/MyRequests'
import MyRequestsAbsence from '../components/MyRequests/MyRequestsAbsence'
import OccurrenceRequest from '../components/OccurrenceRequest'
import Reporting from '../components/Reporting/Reporting'
import WallChart from '../components/WallChart'
import Booking from '../components/Booking/Booking'
import Designer from '../components/Booking/Designer/Designer'
import Dashboard from '../components/Dashboard/Dashboard'
import Settings from '../components/Settings'
import PageHeader from '../shared/layout/PageHeader/PageHeader'
import ProtectedRoute from './ProtectedRoute'
import NotificationLogs from '../components/BSSAdmin/NotificationLogs'
import FeatureToggleAdmin from '../components/BSSAdmin/FeatureToggles'
import { Enhancements } from '../components/Enhancements'
import { EnhancementRequest } from '../components/EnhancementRequest'
import { ResponsiveDetectionMobileState } from '../types/responsive-detection-mobile'
import { CallOutRequestDetail } from '../components/EnhancementRequest/CallOutRequestDetail'
import DesignerPage from "../components/Booking/Designer/DesignerPage";


export default function AppRoutes() {
  const { mobileDetection } = useSelector((state: RootStore) => state.responsiveDetection)
  const { isMobile, isMobileLayout }: ResponsiveDetectionMobileState = mobileDetection

  const { activeRoute } = useSelector((state: RootStore) => state.appSettings)

  const mobileLayout = isMobile || isMobileLayout

  let landingRoute
  const splt = window.location.pathname.split('/').filter(i => i)
  if (splt.length > 1) {
    // e.g. /wallchart/holidayrequest/####
    const ROOT_PATH = 0
    landingRoute = `/${splt[ROOT_PATH]}`
  }
  const BASE_ROUTE = landingRoute || activeRoute || '/myactions'

  return (
    <Routes>
      <Route element={<ProtectedRoute />}>
        <Route path="/myactions/absenceoccurrence/:id/:refreshurlepoch" element={<Absence />} />
      </Route>
      <Route element={<ProtectedRoute />}>
        <Route path="/myactions/lateoccurrence/:id/:refreshurlepoch" element={<Late />} />
      </Route>
      <Route element={<ProtectedRoute />}>
        <Route
          path={`${BASE_ROUTE}/holidayrequest/:id`}
          element={<OccurrenceRequest occurrenceType="Holiday" />}
        />
      </Route>
      <Route element={<ProtectedRoute />}>
        <Route
          path={`${BASE_ROUTE}/workfromhomerequest/:id`}
          element={<OccurrenceRequest occurrenceType="Work From Home" />}
        />
      </Route>
      <Route element={<ProtectedRoute />}>
        <Route
          path={`${BASE_ROUTE}/shiftrequest/:id`}
          element={<OccurrenceRequest occurrenceType="Shift" />}
        />
      </Route>
      <Route element={<ProtectedRoute />}>
        <Route
          path={`${BASE_ROUTE}/lieurequest/:id`}
          element={<OccurrenceRequest occurrenceType="Lieu" />}
        />
      </Route>
      <Route element={<ProtectedRoute />}>
        <Route
          path={`${BASE_ROUTE}/dayoffrequest/:id`}
          element={<OccurrenceRequest occurrenceType="Day Off" />}
        />
      </Route>
      <Route element={<ProtectedRoute />}>
        <Route
          path={`${BASE_ROUTE}/manuallieurequest/:id`}
          element={<ManualOccurrenceRequest occurrenceType="Lieu" />}
        />
      </Route>
      <Route element={<ProtectedRoute />}>
        <Route path={`${BASE_ROUTE}/lateoccurrence/:id`} element={<Late />} />
      </Route>
      <Route element={<ProtectedRoute />}>
        <Route
          path={`${BASE_ROUTE}/manualholidayrequest/:id`}
          element={<ManualOccurrenceRequest occurrenceType="Holiday" />}
        />
      </Route>
      <Route element={<ProtectedRoute />}>
        <Route
          path={`${BASE_ROUTE}/maternityrequest/:id`}
          element={<ManualOccurrenceRequest occurrenceType="Maternity" />}
        />
      </Route>
      <Route element={<ProtectedRoute />}>
        <Route
          path={`${BASE_ROUTE}/paternityrequest/:id`}
          element={<ManualOccurrenceRequest occurrenceType="Paternity" />}
        />
      </Route>
      <Route element={<ProtectedRoute />}>
        <Route
          path={`${BASE_ROUTE}/twilightshiftcoverrequest/:id`}
          element={<ManualOccurrenceRequest occurrenceType="Twilight Shift Cover" />}
        />
      </Route>
      <Route element={<ProtectedRoute />}>
        <Route
          path={`${BASE_ROUTE}/nightshiftcoverrequest/:id`}
          element={<ManualOccurrenceRequest occurrenceType="Night Shift Cover" />}
        />
      </Route>
      <Route element={<ProtectedRoute />}>
        <Route
          path={`${BASE_ROUTE}/courserequest/:id`}
          element={<ManualOccurrenceRequest occurrenceType="Course" />}
        />
      </Route>
      <Route element={<ProtectedRoute />}>
        <Route
          path={`${BASE_ROUTE}/adjustmentrequest/:id`}
          element={<AdjustmentOccurrence occurrenceType="Adjustment" />}
        />
      </Route>
      <Route element={<ProtectedRoute />}>
        <Route
          path={`${BASE_ROUTE}/birthdayrequest/:id`}
          element={<OccurrenceRequest occurrenceType="Birthday" />}
        />
      </Route>
      <Route element={<ProtectedRoute />}>
        <Route
          path={`${BASE_ROUTE}/otherrequest/:id`}
          element={<OccurrenceRequest occurrenceType="Other" />}
        />
      </Route>
      <Route element={<ProtectedRoute />}>
        <Route path={`${BASE_ROUTE}/calloutrequest/:id`} element={<CallOutRequestDetail />} />
      </Route>
      <Route element={<ProtectedRoute />}>
        <Route path={`${BASE_ROUTE}/oncallrequest/:id`} element={<EnhancementRequest />} />
      </Route>
      <Route element={<ProtectedRoute />}>
        <Route path={`${BASE_ROUTE}/nightrequest/:id`} element={<EnhancementRequest />} />
      </Route>
      <Route element={<ProtectedRoute />}>
        <Route path={`${BASE_ROUTE}/overtimerequest/:id`} element={<EnhancementRequest />} />
      </Route>
      <Route element={<ProtectedRoute />}>
        <Route path="/myactions/absenceoccurrence/:id" element={<Absence />} />
      </Route>
      <Route element={<ProtectedRoute />}>
        <Route path="/wallchart/absenceoccurrence/:id" element={<Absence />} />
      </Route>
      <Route element={<ProtectedRoute />}>
        <Route path="/myactions/lateoccurrence" element={<Late />} />
      </Route>

      <Route
        path="/myavailability/absenceoccurrence/:id/:refreshurlepoch"
        element={<MyRequestsAbsence />}
      />
      <Route
        path="/myavailability/requestoccurrence/:id/:refreshurlepoch"
        element={<MyRequests />}
      />
      <Route path="/myavailability/lateoccurrence/:id" element={<Late />} />
      <Route path="/myavailability/absenceoccurrence/:id" element={<MyRequestsAbsence />} />
      <Route path="/myavailability/absenceoccurrence" element={<MyRequestsAbsence />} />

      <Route path="/dashboard/buyrequest/:id" element={<BuySellRequest occurrenceType="Buy" />} />
      <Route path="/dashboard/sellrequest/:id" element={<BuySellRequest occurrenceType="Sell" />} />
      <Route path="/dashboard/lieurequest/:id" element={<Absence />} />
      <Route path="/dashboard/absenceoccurrence/:id" element={<Absence />} />
      <Route path="/dashboard/lateoccurrence/:id" element={<Late />} />
      <Route
        path="/dashboard/courserequest/:id"
        element={<ManualOccurrenceRequest occurrenceType="Course" />}
      />

      <Route element={<ProtectedRoute />}>
        <Route path="/myactions/buyrequest/:id" element={<BuySellRequest occurrenceType="Buy" />} />
      </Route>
      <Route element={<ProtectedRoute />}>
        <Route
          path="/myactions/sellrequest/:id"
          element={<BuySellRequest occurrenceType="Sell" />}
        />
      </Route>
      <Route element={<ProtectedRoute />}>
        <Route
          path="/dashboard"
          element={
            <>
              <PageHeader title={mobileLayout ? 'Dashboard' : undefined} />
              <Dashboard />
            </>
          }
        />
      </Route>
      <Route element={<ProtectedRoute />}>
        <Route path="/myavailability" element={<MyRequests />} />
      </Route>
      <Route element={<ProtectedRoute />}>
        <Route path="/wallchart" element={<WallChart />} />
      </Route>
      <Route element={<ProtectedRoute />}>
        <Route path="/booking" element={<Booking />} />
      </Route>
      <Route element={<ProtectedRoute />}>
        <Route path="/booking/:id" element={<Booking />} />
      </Route>
      <Route element={<ProtectedRoute />}>
        <Route path="/booking/designer" element={<DesignerPage />} />
      </Route>
      <Route element={<ProtectedRoute />}>
        <Route path="/myactions" element={<MyActions />} />
      </Route>
      <Route element={<ProtectedRoute />}>
        <Route path="/enhancements" element={<Enhancements />} />
      </Route>
      <Route element={<ProtectedRoute />}>
        <Route path="/absenceandlate" element={<Reporting />} />
      </Route>
      <Route element={<ProtectedRoute />}>
        <Route path="/holiday" element={<Holiday />} />
      </Route>
      <Route element={<ProtectedRoute />}>
        <Route path="/myextras" element={<MyExtras />} />
      </Route>
      <Route element={<ProtectedRoute />}>
        <Route path="/settings" element={<Settings />} />
      </Route>
      <Route path="/login/callback" element={<LoginCallback />} />
      <Route element={<ProtectedRoute />}>
        <Route
          path="/"
          element={
            <>
              <PageHeader title={mobileLayout ? 'Dashboard' : undefined} />
              <Dashboard />
            </>
          }
        />
      </Route>
      <Route path="/myavailability/:urlViewType/:filters" element={MyRequests} />
      <Route path="/bssadmin" element={<></>} />
      <Route path="/bssadmin/featuretoggles" element={<FeatureToggleAdmin />} />
      <Route path="/bssadmin/notificationlogs" element={<NotificationLogs />} />
    </Routes>
  )
}

import {
  Autocomplete,
  AutocompleteRenderInputParams,
  AutocompleteRenderOptionState,
  Box,
  Grid,
  TextField,
} from '@mui/material'
import { Info } from '@mui/icons-material'
import { useSelector } from 'react-redux'
import React, {useCallback, useEffect, useState} from 'react'
import { isBefore } from 'date-fns'
import { RequestType } from '../../models'
import { hideHeaderDrawer } from '../../redux/reducers/appSettingsReducer'
import { RootStore, useAppDispatch } from '../../redux/store'
import Alert from '../../shared/UI/Alert/Alert'
import Button from '../../shared/UI/Button'
import DrawerFooter from '../../shared/UI/DrawerFooter'
import HeadingTwo from '../../shared/UI/HeadingTwo'
import Paragraph from '../../shared/UI/Paragraph'

import { callOutFromContent, globalContent, absenceFormLabels } from '../../utils/constants'
import DateTimePicker from '../../shared/UI/DateTimePicker'
import ManagerEmployeeDropDown from '../MultiDayRequest/ManagerEmployeeDropDown'
import { RowRadioGroup } from './RowRadioGroup/RowRadioGroup'
import { ValidationErrors, validateFormValues } from './validations'
import {
  calloutInitialState,
  setCalloutDetails,
  setDateRange,
  setRequestType,
  timeOffRequestsState,
  setPaidHoursOverride,
  setCalculatedPaidHours,
  setConflicts
} from '../../redux/reducers/timeOffRequestsReducer'
import { getDurationInFractionalHours } from '../../utils/date-utils'
import { contentState, setCallOutMenuItems } from '../../redux/reducers/contentReducer'
import { enhancementService } from '../../services/enhancementService'
import { CalculateCalloutPaidHoursResponse, PayPeriodResponse, SubmitStatus } from '../../models/enhancement'
import { useDefaultErrorHandler } from '../../utils/Hooks/useDefaultErrorHandler'
import { CustomWidthTooltip } from '../../shared/UI/CustomToolTip/CustomTooltip'
import PayPeriod from '../PayPeriod'
import { v2MyActionsService } from '../../services/myActionsServiceV2'

const enhancementType = RequestType.CALL_OUT_M
export const callOutTestId = 'callout'

export interface CallOutEnhancementPropsBase {
  isViewOnly?: boolean
  isManager?: boolean
  isEmployeeSelected?: boolean
  disabledDates?: string[]
  submitLoading?: boolean
  onViewOnlyDrawerClose?: () => void
  handleSubmit?: (event: React.FormEvent<HTMLFormElement>, type: RequestType) => void
  handleClose?: (event: React.FormEvent<HTMLFormElement>, submission?: boolean) => void
  payPeriod?: PayPeriodResponse
  employeeSelected?: number | null
}
interface ViewOnlyProps extends CallOutEnhancementPropsBase {
  isViewOnly: true
  onViewOnlyDrawerClose: () => void
}

interface EditableProps extends CallOutEnhancementPropsBase {
  isViewOnly?: false
  handleSubmit: (event: React.FormEvent<HTMLFormElement>, type: RequestType) => void
}

export type CallOutEnhancementProps = ViewOnlyProps | EditableProps

const isPayPeriodDataMissing = (payPeriod: PayPeriodResponse | undefined) => 
  !payPeriod || 
  !payPeriod.managerCutOff || 
  !payPeriod.employeeCutOff

const renderPayPeriod = (payPeriod: PayPeriodResponse | undefined, isManager: boolean | undefined) => {
  if (isPayPeriodDataMissing(payPeriod)) {
    return null
  }

  return <PayPeriod isManager={isManager} payPeriod={payPeriod} />
}

const shouldDisplayStaticPaidHours = (status: string, paidHours: number | undefined): paidHours is number =>
  (paidHours !== undefined) &&
  ([SubmitStatus.APPROVED, SubmitStatus.AMENDED, SubmitStatus.SUBMITTED] as string[]).includes(status)

export function CallOutEnhancement({
  isViewOnly,
  isManager,
  disabledDates,
  isEmployeeSelected,
  submitLoading,
  employeeSelected,
  onViewOnlyDrawerClose,
  handleSubmit,
  handleClose,
  payPeriod,
}: CallOutEnhancementProps) {
  const dispatch = useAppDispatch()
  const defaultErrorHandler = useDefaultErrorHandler()
  // States
  const [formErrors, setFormErrors] = useState<ValidationErrors>()
  const [duration, setDuration] = useState<number>(0)
  const [calculatedCallout, setCalculatedCallout] = useState<CalculateCalloutPaidHoursResponse>()
  const [displayDuration, setDisplayDuration] = useState<number>(0)
  const [paidHours, setPaidHours] = useState<number>()
  const { dateRange, calloutDetails, conflicts } = useSelector<RootStore, timeOffRequestsState>(
    (state: RootStore) => state.timeOff
  )

  const { callOutMenuItems } = useSelector<RootStore, contentState>(
    (state: RootStore) => state.content
  )

  /**
   * Check if the selected duration is valid
   */
  const validateAndSetDuration = () => {
    const [start, end] = dateRange
    if (start && end) {
      const hours = getDurationInFractionalHours(start, end)
      setFormErrors(prevState => ({
        ...prevState,
        negetiveDuration: hours <= 0,
      }))

      setDuration(hours)
      if (hours <= 0) {
        setDisplayDuration(0)
      } else {
        setDisplayDuration(hours)
      }
    }
  }
  /**
   * Set enhancement request type on component render,
   * and reset call out details on component unmount
   */
  useEffect(() => {
    if (isManager) {
      dispatch(setRequestType(RequestType.CALL_OUT_M))
    } else {
      dispatch(setRequestType(RequestType.CALL_OUT))
    }
    return () => {
      dispatch(setCalloutDetails(calloutInitialState))
    }
  }, [])

  /**
   * Fetch callout menu items if not present in the store
   */
  useEffect(() => {
    if (!callOutMenuItems)
      enhancementService
        .getCallOutMenuItems()
        .then(response => {
          dispatch(setCallOutMenuItems(response))
        })
        .catch(defaultErrorHandler)
  }, [callOutMenuItems])

  /**
   * Revalidate when date range changes
   */
  useEffect(() => {
    validateAndSetDuration()
  }, [dateRange])

  const renderAutocompleteInput = (params: AutocompleteRenderInputParams, label: string) => (
    <TextField
      {...params}
      label={callOutFromContent[label]}
      variant="outlined"
      error={formErrors?.[label]}
      helperText={globalContent.required}
      fullWidth
    />
  )

  /**
   * Fetch paid hours dynamically for manager manual requests
   */
  useEffect(() => {
    const calculatePaidHours = async () => {
      if (employeeSelected && dateRange) {
        const [start, end] = dateRange
        if (start && end && isBefore(start, end)) {
          v2MyActionsService.postCalculateCalloutPaidHours({
            employeeId: employeeSelected,
            calloutStart: start.toISOString(),
            calloutEnd: end.toISOString()
          }).then(result => {
            setCalculatedCallout(result)
            setPaidHours(result.calculatedPaidHours)
            dispatch(setCalculatedPaidHours(result.calculatedPaidHours))
            dispatch(setConflicts(false))
          })
        }
      }
    }

    if (isManager && !isViewOnly && employeeSelected) {
      calculatePaidHours()
    }

  }, [dateRange, employeeSelected, dispatch, conflicts])

  const handlePaidHoursOverrideChange = (override: number) => {
    setPaidHours(override)
    dispatch(setPaidHoursOverride(override))
  }

  const renderAutocompleteEntry = useCallback((
    props: React.HTMLAttributes<HTMLLIElement>,
    option: string,
    state: AutocompleteRenderOptionState
  ) => (
    <li {...props} key={`${option}-${state.index}`}>
      <span>{option}</span>
    </li>
  ), [])

  return (
    <Grid
      component="form"
      noValidate
      container
      columnSpacing={6}
      rowSpacing={{ xs: 3, sm: 4 }}
      data-testid={callOutTestId}
      onSubmit={(event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault()
        const { isValid, validationErrors } = validateFormValues(dateRange, calloutDetails)
        setFormErrors(validationErrors)
        validateAndSetDuration()
        if (isValid && !formErrors?.negetiveDuration && handleSubmit) {
          handleSubmit(event, enhancementType)
        }
      }}
    >
      {isManager && !isViewOnly && (
        <>
          <Grid item xs={12} lg={6}>
            <ManagerEmployeeDropDown />
          </Grid>
          <Grid item xs={12} lg={6}>
            {!isEmployeeSelected && (
              <Alert
                severity="info"
                message={absenceFormLabels.selectEmployee}
                testId="select-employee-alert"
              />
            )}
          </Grid>
        </>
      )}
      {renderPayPeriod(payPeriod, isManager)}
      {isEmployeeSelected || !isManager ? (
        <>
          <Grid item xs={12} lg={6}>
            <Grid container spacing={4}>
              <Grid item xs={12}>
                <Paragraph weight="bold">{globalContent.details}</Paragraph>
              </Grid>
              <Grid item xs={12}>
                <DateTimePicker
                  value={dateRange[0]}
                  maxDate={new Date()}
                  onChange={date => dispatch(setDateRange([date, dateRange[1]]))}
                  label={globalContent.from}
                  error={formErrors?.start}
                  helperText={globalContent.required}
                  minutesStep={5}
                  disabledDates={disabledDates}
                  dataTestId={`${callOutTestId}-from-date`}
                  disabled={isViewOnly}
                />
              </Grid>
              <Grid item xs={12}>
                <DateTimePicker
                  value={dateRange[1]}
                  maxDate={new Date()}
                  onChange={date => dispatch(setDateRange([dateRange[0], date]))}
                  label={globalContent.to}
                  error={formErrors?.end}
                  helperText={globalContent.required}
                  disabledDates={disabledDates}
                  minutesStep={5}
                  dataTestId={`${callOutTestId}-to-date`}
                  disabled={isViewOnly}
                />
                {formErrors?.negetiveDuration && (
                  <Alert
                    severity="error"
                    message={callOutFromContent.endTimeBeforeStartTime}
                    testId="negative-duration-alert"
                  />
                )}
              </Grid>
              <Grid item xs={12}>
                <Autocomplete
                  options={callOutMenuItems?.department || []}
                  value={calloutDetails.calledOutBy}
                  getOptionLabel={option => option}
                  renderInput={params => renderAutocompleteInput(params, 'calledOutBy')}
                  data-testid={`${callOutTestId}-department-dropdown`}
                  onInputChange={(_e, value) =>
                    dispatch(setCalloutDetails({ ...calloutDetails, calledOutBy: value }))
                  }
                  renderOption={renderAutocompleteEntry}
                  disabled={isViewOnly}
                />
              </Grid>
              <Grid item xs={12}>
                <Autocomplete
                  options={callOutMenuItems?.issueType || []}
                  value={calloutDetails.issueType}
                  getOptionLabel={option => option}
                  renderInput={params => renderAutocompleteInput(params, 'issueType')}
                  data-testid={`${callOutTestId}-issueType-dropdown`}
                  onInputChange={(_e, value) =>
                    dispatch(setCalloutDetails({ ...calloutDetails, issueType: value }))
                  }
                  renderOption={renderAutocompleteEntry}
                  disabled={isViewOnly}
                />
              </Grid>
              <RowRadioGroup
                value={calloutDetails.escalationRequired}
                label={callOutFromContent.escalated}
                onRadioChange={newValue =>
                  dispatch(setCalloutDetails({ ...calloutDetails, escalationRequired: newValue }))
                }
                tooltipText={callOutFromContent.escalatedToTooltip}
                testid={`${callOutTestId}-radio-escalated`}
                disabled={isViewOnly}
              />
              {calloutDetails.escalationRequired ? (
                <>
                  <Grid item xs={12}>
                    <Autocomplete
                      options={callOutMenuItems?.escalationType || []}
                      value={calloutDetails.escalationType}
                      getOptionLabel={option => option}
                      renderInput={params => renderAutocompleteInput(params, 'escalationType')}
                      data-testid={`${callOutTestId}-escalationType-dropdown`}
                      onInputChange={(_e, value) =>
                        dispatch(setCalloutDetails({ ...calloutDetails, escalationType: value }))
                      }
                      renderOption={renderAutocompleteEntry}
                      disabled={isViewOnly}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Autocomplete
                      options={callOutMenuItems?.escalateTo || []}
                      value={calloutDetails.escalatedTo}
                      getOptionLabel={option => option}
                      renderInput={params => renderAutocompleteInput(params, 'escalatedTo')}
                      data-testid={`${callOutTestId}-escalatedTo-dropdown`}
                      onInputChange={(_e, value) =>
                        dispatch(setCalloutDetails({ ...calloutDetails, escalatedTo: value }))
                      }
                      renderOption={renderAutocompleteEntry}
                      disabled={isViewOnly}
                    />
                  </Grid>
                </>
              ) : null}
              <RowRadioGroup
                value={calloutDetails.transferToAnotherTeam}
                label={callOutFromContent.transferredToTeam}
                onRadioChange={newValue =>
                  dispatch(
                    setCalloutDetails({ ...calloutDetails, transferToAnotherTeam: newValue })
                  )
                }
                testid={`${callOutTestId}-radio-transferredToTeam`}
                disabled={isViewOnly}
              />
              {calloutDetails.transferToAnotherTeam ? (
                <Grid item xs={12}>
                  <Autocomplete
                    options={callOutMenuItems?.team || []}
                    value={calloutDetails.team}
                    getOptionLabel={option => option}
                    renderInput={params => renderAutocompleteInput(params, 'team')}
                    data-testid={`${callOutTestId}-team-dropdown`}
                    onInputChange={(_e, value) =>
                      dispatch(setCalloutDetails({ ...calloutDetails, team: value }))
                    }
                    renderOption={renderAutocompleteEntry}
                    disabled={isViewOnly}
                  />
                </Grid>
              ) : null}
            </Grid>
          </Grid>
          <Grid item xs={12} lg={6}>
            <Grid container spacing={4}>
              <Grid item xs={12} lg={6}>
                <Paragraph weight="bold">{globalContent.requestSummary}</Paragraph>
              </Grid>

              <Grid item xs={12}>
                <Autocomplete
                  options={callOutMenuItems?.conclusion || []}
                  value={calloutDetails.conclusion}
                  getOptionLabel={option => option}
                  renderInput={params => renderAutocompleteInput(params, 'conclusion')}
                  data-testid={`${callOutTestId}-conclusion-dropdown`}
                  onInputChange={(_e, value) =>
                    dispatch(setCalloutDetails({ ...calloutDetails, conclusion: value }))
                  }
                  renderOption={renderAutocompleteEntry}
                  disabled={isViewOnly}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  value={calloutDetails.jiraReference}
                  onChange={e =>
                    dispatch(
                      setCalloutDetails({ ...calloutDetails, jiraReference: e.target.value })
                    )
                  }
                  id="jiraReference"
                  label={callOutFromContent.jiraReference}
                  helperText={globalContent.required}
                  error={formErrors?.jiraReference}
                  disabled={isViewOnly}
                />
              </Grid>
              <Grid item xs={12}>
                <Autocomplete
                  options={callOutMenuItems?.product || []}
                  value={calloutDetails.product}
                  getOptionLabel={option => option}
                  renderInput={params => renderAutocompleteInput(params, 'product')}
                  data-testid={`${callOutTestId}-product-dropdown`}
                  onInputChange={(_e, value) =>
                    dispatch(setCalloutDetails({ ...calloutDetails, product: value }))
                  }
                  renderOption={renderAutocompleteEntry}
                  disabled={isViewOnly}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id="reason"
                  label={callOutFromContent.reason}
                  fullWidth
                  rows={5}
                  multiline
                  value={calloutDetails.reason}
                  onChange={e =>
                    dispatch(setCalloutDetails({ ...calloutDetails, reason: e.target.value }))
                  }
                  helperText={globalContent.required}
                  error={formErrors?.reason}
                  disabled={isViewOnly}
                />
              </Grid>
              <RowRadioGroup
                value={calloutDetails.codeChange}
                label={callOutFromContent.codeChangeReleased}
                onRadioChange={newValue =>
                  dispatch(setCalloutDetails({ ...calloutDetails, codeChange: newValue }))
                }
                testid={`${callOutTestId}-radio-codeChange`}
                disabled={isViewOnly}
              />
              <RowRadioGroup
                value={calloutDetails.logInRequired}
                label={callOutFromContent.logInRequired}
                onRadioChange={newValue => {
                  dispatch(setCalloutDetails({ ...calloutDetails, logInRequired: newValue }))
                }}
                testid={`${callOutTestId}-radio-logInRequired`}
                tooltipText={callOutFromContent.loginRequiredTooltip}
                disabled={isViewOnly}
              />
              {duration ? (
                <Grid
                  item
                  xs={12}
                >
                  {isManager && !isViewOnly &&
                    <Box sx={{display: 'flex', justifyContent: 'space-between', marginTop: 'auto', flexGrow: 1, marginBottom: 1.3}}>
                      <HeadingTwo title={globalContent.callouts} />
                      <Box display="flex" alignItems="center" sx={{marginRight: 2.8}}>
                        <HeadingTwo title={calculatedCallout ? `${calculatedCallout.callOutPosition} of ${calculatedCallout.callOutCount}`: ''} />
                      </Box>
                    </Box>
                  }
                  <Box sx={{display: 'flex', justifyContent: 'space-between', marginTop: 'auto', flexGrow: 1}}>
                    <HeadingTwo title={globalContent.duration} />
                    <Box display="flex" alignItems="center" sx={{marginRight: 2.8}} data-testid={`${callOutTestId}-duration`}>
                      <HeadingTwo title={`${displayDuration.toFixed(2)} Hours`} />
                    </Box>
                  </Box>
                  {isManager && !isViewOnly ?
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: 1.3, flexGrow: 1 }}>
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <HeadingTwo title={globalContent.paidHours} />
                        <CustomWidthTooltip
                          title={callOutFromContent.adjutedHoursTooltip}
                          data-testid="adjusted-hours-tooltip"
                        >
                          <Info fontSize="small" sx={{ color: '#20C5A0', marginLeft: '4px' }} />
                        </CustomWidthTooltip>
                      </Box>
                      <Box display="flex" alignItems="center">
                        <TextField
                          inputProps={{
                            step: "0.10",
                            min: "0.00",
                            max: "999.99",
                          }}
                          title='Override PaidHours'
                          placeholder='Overriden Hours'
                          value={paidHours?.toFixed(2)}
                          type='number'
                          onChange={(e) => handlePaidHoursOverrideChange(Number(e.target.value))}
                          sx={{ marginRight: '4px', width: '130px' }}
                        />
                      </Box>
                    </Box> : 
                    <Box sx={{display: 'flex', justifyContent: 'space-between', marginTop: 1.3, flexGrow: 1}}>
                      <HeadingTwo title={globalContent.paidHours} />
                      <Box display="flex" alignItems="center">
                        {shouldDisplayStaticPaidHours(calloutDetails.status, calloutDetails.paidHours) &&
                          <HeadingTwo title={`${calloutDetails.paidHours.toFixed(2)} Hours`} data-testid="callout-paid-hours" />
                        }
                        <CustomWidthTooltip
                          title={callOutFromContent.adjutedHoursTooltip}
                          data-testid="adjusted-hours-tooltip"
                        >
                          <Info fontSize="small" sx={{ color: '#20C5A0', marginLeft: '4px' }} />
                        </CustomWidthTooltip>
                      </Box>
                    </Box>
                  }
                </Grid>
              ) : null}
            </Grid>
          </Grid>
        </>
      ) : null}
      {isViewOnly && (
        <DrawerFooter>
          <Button
            color="secondary"
            label="Close"
            onClick={() => {
              dispatch(setDateRange([null, null]))
              onViewOnlyDrawerClose()
            }}
          />
        </DrawerFooter>
      )}
      {!isViewOnly && (isEmployeeSelected || !isManager) ? (
        <DrawerFooter>
          <Button
            color="secondary"
            label="Cancel"
            onClick={event => {
              dispatch(hideHeaderDrawer())
              dispatch(setDateRange([null, null]))
              handleClose?.(event, false)
            }}
            style={{ ml: 4 }}
          />
          <Button
            label="Submit"
            type="submit"
            loading={submitLoading}
            disabled={formErrors?.negetiveDuration}
          />
        </DrawerFooter>
      ) : null}
    </Grid>
  )
}

import styled from 'styled-components'
import { Typography } from '@mui/material'
import palette from '../../../theme/palette'
import { isMobilePortrait } from '../../../theme/deviceChecks'

interface Props {
  status: string | undefined
  size?: '11px' | '13px' | '16px' | '19px' | '21px'
}

interface StyledTypographyProps {
  status: string | undefined
  size: string
  background: string | undefined
}

const StyledTypography = styled(Typography)<StyledTypographyProps>`
  &.MuiTypography-root {
    font-weight: 500;
    border-radius: 50px;
    padding: 4px 8px;
    width: auto;
    ${({ size }) => `font-size: ${size};`}
    ${({ background }) => `color: ${background ? '#fff' : '#000'};`}
    ${({ background }) => `background-color: ${background || 'transparent'};`}

    ${isMobilePortrait()} {
      .DrawerHeader &.MuiTypography-root {
        position: absolute;
        top: 36px;
      }
    }
  }
`

const paletteEntries = Object.entries(palette.status)

function StatusLabel({ status, size = '11px' }: Props) {
  let background: string | undefined

  if (status === 'Pending Submission') {
    background = palette.status.pendingSubmission.color
    } else {
      for (let i = 0; i < paletteEntries.length; i += 1) {
        if (paletteEntries[i][1].type.toLowerCase() === status?.toLowerCase()) {
          background = paletteEntries[i][1].color
          break
        }
      }
  }
    
  return (
    <StyledTypography
      status={status}
      size={size}
      background={background}
      data-testid={`status-label-${status}`}
    >
      {status}
    </StyledTypography>
  )
}

export default StatusLabel

import { BaseResponse } from '../../types/base-response'

export interface BookingSliderPosition {
  slidePosition: number
  buttonClick: boolean
}

export interface BookingFloorPlan {
  id: number
  categoryId?: number
  categoryName?: string
  locationId: number
  locationName?: string
  name: string
  image?: string
}

export interface BookingSearchResultPort {
  id: number
  featureId: number
  x: string
  y: string
  radius: number
  fill: string
}

export interface BookingSearchResultMeta {
  available: boolean
  enabled: boolean
  booked: boolean
}

export interface BookingFeatureType {
  id: number
  label: string
}

export interface BookingDeskDetails {
  deskName?: string
  location?: string
  floorPlanName?: string
}

export interface DatesFromBookings {
  date: number
  featureId: number
  fromTime: string
  toTime: string
}

export interface BookingBookingPayload {
  id: number
  employeeId: number | null
  employeeName: string
  displayName: string
  floorPlanId: number
  featureId: number
  featureType: BookingFeatureType
  fromDate: string
  fromTime: string
  toDate: string
  toTime: string
  createdByDate: string
  status: 'Pending' | 'Accepted' | 'Declined' | 'Cancelled'
  statusId?: number
}

export interface BookingBookingPostBody {
  id: number
  employeeId: number | null
  employeeName: string
  floorPlanId: number
  featureId: number
  featureType: BookingFeatureType
  fromDate: string
  fromTime: string
  toDate: string
  toTime: string
  createdByDate: string
  createdByEmployeeId: number
  status: 'Pending' | 'Accepted' | 'Declined' | 'Cancelled'
  statusId?: number
  deskDetails?: BookingDeskDetails
}

export interface BookingPort {
  id: number
  featureId: number
  x: string
  y: string
  radius: number
  fill: string
}

export interface BookingComponent {
  id: number
  name: string
}

export interface BookingZone extends Partial<BaseResponse> {
  id: number
  name: string
  floorPlanId: number
  belongsTo: number
  additionalInfo: string
  floorPlanCategoryId?: number
  locationId?: number
}

export interface BaseFeature {
  cIdx?: number
  rIdx?: number
  id: number
  floorPlanId: number
  floorPlanCategoryId: number
  locationId: number
  typeId: number
  type: string
  statusId: number
  status: string
  label: string
  x: number
  y: number
  width: number
  height: number
  fill: string
  borderRadius: number
  ports?: BookingPort[]
  createdById: number
  components?: BookingComponent[]
  additionalInfo: string
  zone?: Partial<BookingZone>
}

export interface DeskFeature extends BaseFeature {
  typeId: 1;
  width: number;
  height: number;
  rotationAngle?: number;
  qrCode?: string;
  assetTag?: string;
  desc?: DeskDesc;
}

export interface OfficeFeature extends BaseFeature {
  typeId: 10;
  width: number;
  height: number;
}

export interface RectFeature extends BaseFeature {
  typeId: 4;
  width: number;
  height: number;
}

export interface WallFeature extends BaseFeature {
  typeId: 7;
  startPoint: { x: number; y: number };
  endPoint: { x: number; y: number };
  thickness: number;
}

export interface LabelFeature extends BaseFeature {
  typeId: 9;
  color: string;
  fontSize: number;
  chip?: boolean;
}

export interface TextFeature extends BaseFeature {
  typeId: 5;
  color: string;
  fontSize: number;
  chip?: boolean;
}

export interface ZoneFeature extends BaseFeature {
  typeId: 8;
  name: string;
  belongsTo: number;
}

export type BookingFeature = DeskFeature | OfficeFeature | WallFeature | LabelFeature | ZoneFeature | TextFeature | RectFeature;

export interface DeskDesc {
  deskDesc?: string;
}

export function isDeskFeature(feature: BookingFeature): feature is DeskFeature {
  return feature.typeId === 1;
}

export function isOfficeFeature(feature: BookingFeature): feature is OfficeFeature {
  return feature.typeId === 10;
}

export function isWallFeature(feature: BookingFeature): feature is WallFeature {
  return feature.typeId === 7;
}

export function isTextFeature(feature: BookingFeature): feature is LabelFeature {
  return feature.typeId === 9;
}

export function isZoneFeature(feature: BookingFeature): feature is ZoneFeature {
  return feature.typeId === 8;
}

export interface MostUsedDesk {
  deskName?: string
  deskId?: number
  locationId?: number
  floorPlanId?: number
  zoneId?: number
}

export interface BookingFeatures {
  id: number
  label: string
  features: BookingFeature[]
}

export interface BookingFeaturesPayload {
  features: BookingFeature[]
}

export interface BookingCheckInOut {
  id: number
  bookingId: number
  userId: number
  type: string
  actionDate: Date
  createdDate: Date
  anyIssues: string
}

export interface BookingEmployeeBooking {
  createdBy: any
  id: number
  employeeId: number
  displayName: string
  fromDate: string
  fromTime: string
  toDate: string
  toTime: string
  statusId: number
  feature: BookingFeature
  floorPlan: BookingFloorPlan
  zone: BookingZone
  department: string
  checkInOut: BookingCheckInOut[]
}

export interface BookingsByEmployeePayload extends BaseResponse {
  bookings: BookingEmployeeBooking[]
}

export interface BookingSearchProps {
  date: string
  dateTo?: string
  from: string
  to: string
  floorplanId: number
}

export interface BookingFloorPlans {
  floorPlans: BookingFloorPlan[]
}

export interface BookingFloorPlanCategory {
  id: number
  label: string
}

export interface BookingFloorPlanCategories {
  data: BookingFloorPlanCategory[]
}

export interface BookingLocation {
  id: number
  name: string
}

export interface BookingCheckInOutPost {
  id: number
  bookingID: number
  userId: number
  type: string
  actionDate: string
  anyIssues: string
  deskName: string
}
export interface BookingCheckInOutPayload extends BaseResponse {
  type: string
  title: string
  status: number
  detail: string
  instance: string
  additionalProp1: string
  additionalProp2: string
  additionalProp3: string
}

export interface BookingLocations {
  locations: BookingLocation[]
}

export interface BookingPostResponse extends BaseResponse {
  type: string
  title: string
  status: number
  detail: string
  instance: string
  additionalProp1: string
  additionalProp2: string
  additionalProp3: string
}

export interface BlockBookingPostResponse {
  bookings: BookingPostResponse[]
  status: number
}

export interface BookingSearchFeature {
  id: number
  floorPlanId: number
  floorPlanCategoryId: number
  locationId: number
  typeId: number
  label: string
  statusId: number
  status: string
  components?: BookingComponent[]
  additionalInfo?: string
}

export interface BookingSearchPayload extends BaseResponse {
  bookings: BookingBookingPayload[]
}

export interface BookingRequestPostBody {
  employeeId: number
  employeeName: string
  floorPlanId: number
  featureId: number
  fromDate: string
  toDate: string
  fromTime: string
  toTime: string
  createdByEmployeeId: number
}

export const InitialBookingFeature: BookingFeature = {
  id: 0,
  x: 0,
  y: 0,
  width: 50,
  height: 75,
  statusId: 0,
  label: '',
  borderRadius: 10,
  floorPlanId: 0,
  floorPlanCategoryId: 0,
  locationId: 0,
  typeId: 1,
  type: '',
  status: '',
  fill: '',
  createdById: 0,
  additionalInfo: '',
  zone: {
    id: 0,
    floorPlanId: 0,
    name: '',
    belongsTo: 0,
    additionalInfo: '',
    errors: [],
  },
}

export interface BookingCancellationPostBody {
  bookingId: number
  deskDetails?: BookingDeskDetails
  reasonTypeId: number
  comments: string
  createdBy: number
}

export interface BookingProblemDetailPostResponse extends BaseResponse {
  type?: string
  title?: string
  detail?: string
  instance?: string
}

export interface BookingBlockBooking {
  floorPlanId: number
  featureId: number
  status: string
  fromDate: string
  fromTime: string
  toDate: string
  toTime: string
}

export interface BookingBlockBookingPostBody {
  employeeId: number
  employeeName: string
  bookings: BookingBlockBooking[]
  deskDetails?: BookingDeskDetails
}

export interface ForwardDeskBookingsResponse extends BaseResponse {
  forwardBookings: number | undefined
}

export interface MostUsedDeskResponse extends BaseResponse {
  response: MostUsedDesk
}

export interface BookingFloorPlanResponse extends BaseResponse {
  plan: BookingFloorPlan
}

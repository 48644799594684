import React, { useState } from 'react'
import { Backdrop, Chip, Fade, useMediaQuery } from '@mui/material'
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined'
import Card from '@mui/material/Card'
import SwipeableDrawer from '@mui/material/SwipeableDrawer'
import Popper from '@mui/material/Popper'
import ButtonSmall from '../../../shared/UI/ButtonSmall'
import FilterPanel from './FilterPanel'
import { MyActionsFilterProps } from './Types'
import { filterBtnStyles } from './Styles'
import CounterLabel from '../../../shared/UI/CounterLable/CounterLabel'

const desktopCardWidth = '530px'

function MyActionsFilter(filterProps: MyActionsFilterProps) {
  const [open, setOpen] = useState(false)
  const [anchorEl, setAnchorEl] = useState(null)
  const isMobile = useMediaQuery('(max-width:600px)')

  const handleClick = (event: { currentTarget: React.SetStateAction<null> }) => {
    setAnchorEl(event?.currentTarget)
    setOpen(!open)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const totalFilteredItems = (
    <Chip
      color="primary"
      variant="filled"
      data-testid="request-count"
      label={<CounterLabel count={filterProps.requestsCount} />}
      size="small"
      sx={filterBtnStyles}
    />
  )

  return (
    <div>
      <ButtonSmall
        label="Filters"
        variant="outlined"
        onClick={e => handleClick(e as { currentTarget: React.SetStateAction<null> })}
        dataTestId="toggle-filter"
        startIcon={<FilterAltOutlinedIcon />}
        sx={{ margin: 0, mb: 3, p: '7px', minWidth: '120px' }}
        endIcon={totalFilteredItems}
      />

      {isMobile ? (
        <SwipeableDrawer
          anchor="bottom"
          open={open}
          onClose={handleClose}
          onOpen={() => setOpen(true)}
          PaperProps={{ sx: { maxHeight: 'calc(100% - 80px)' } }}
        >
          <div style={{ overflowY: 'scroll' }}>
            <FilterPanel {...filterProps} handleClose={handleClose} />
          </div>
        </SwipeableDrawer>
      ) : (
        <>
          <Backdrop
            open={open}
            onClick={handleClose}
            sx={{
              zIndex: 1,
              opacity: 0.2,
              backgroundColor: 'transparent',
              boxShadow: 'none',
            }}
          />
          <Popper
            open={open}
            anchorEl={anchorEl}
            placement="bottom-start"
            sx={{ mt: 1, zIndex: 2 }}
            modifiers={[{ name: 'offset', options: { offset: [0, 10] } }]}
            transition
          >
            {({ TransitionProps }) => (
              <Fade {...TransitionProps} timeout={500}>
                <Card sx={{ maxWidth: desktopCardWidth }}>
                  <FilterPanel {...filterProps} handleClose={handleClose} />
                </Card>
              </Fade>
            )}
          </Popper>
        </>
      )}
    </div>
  )
}

export default MyActionsFilter
